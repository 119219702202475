export enum PAYMENT_STATUS {
	CANCELLED = 'CANCELLED',
	EXPIRED = 'EXPIRED',
	TRIAL = 'TRIAL',
	TRIAL_ABOUT_TO_EXPIRE = 'TRIAL_ABOUT_TO_EXPIRE',
}

export enum SHOPIFY_USAGE {
	SHOPIFY_ONBOARDING = 'SHOPIFY_ONBOARDING',
	SHOPIFY_PRICING = 'SHOPIFY_PRICING',
	BASE = 'BASE',
}

export enum FLOW {
	STOP = 'stop',
	CONTINUE = 'continue',
}

export enum WebStorageKeys {
	QUICK_NAV_NAVIGATION_FROM = 'quickNavNavigationFrom',
}

export enum MESSAGE_TEMPLATE_COMPONENT_TYPE {
	BODY = 'BODY',
	HEADER = 'HEADER',
	BUTTONS = 'BUTTONS',
	FOOTER = 'FOOTER',
	LIMITED_TIME_OFFER = 'LIMITED_TIME_OFFER',
	SECURITY_DISCLAIMER = 'SECURITY_DISCLAIMER',
	CODE_EXPIRATION = 'CODE_EXPIRATION',
}

export enum TemplateType {
	STANDARD = 'STANDARD',
	INTERACTIVE = 'INTERACTIVE',
	CAROUSEL = 'CAROUSEL',
	LIMITED_TIME_OFFER = 'LIMITED_TIME_OFFER',
	AUTHENTICATION = 'AUTHENTICATION',
}

export enum componentTypes {
	header = 'header',
	lto = 'limited_time_offer',
	coupon_code = 'coupon_code',
	copy_code = 'copy_code',
	url = 'url',
	body = 'body',
	buttons = 'buttons',
	text = 'text',
	button = 'button',
	quick_reply = 'quick_reply',
	payload = 'payload',
	add_security_recommendation = 'add_security_recommendation',
	code_expiration_minutes = 'code_expiration_minutes',
}

export enum NODE_TYPE {
	ACTN_NODE = 'ACTN_NODE',
	CAMPAIGN_NODE = 'CAMPAIGN_NODE',
	CARD_NODE = 'CARD_NODE',
	CONVERSATION_STATUS_NODE = 'CONVERSATION_STATUS_NODE',
	EVAL_LOGIC = 'EVAL_LOGIC',
	FAQ_FILTER = 'FAQ_FILTER',
	FEEDBACK_NODE = 'FEEDBACK_NODE',
	FILE_UPLOAD = 'FILE_UPLOAD',
	FORM_NODE = 'FORM_NODE',
	HTTP_REQUEST = 'HTTP_REQUEST',
	JSON_API = 'JSON_API',
	IDENTITY_NODE = 'IDENTITY_NODE',
	INTEGRATION = 'INTEGRATION',
	LIVE_CHAT_CATEGORY = 'LIVE_CHAT_CATEGORY',
	PAUSE_NODE = 'PAUSE_NODE',
	PYTHON_SCRIPT_NODE = 'PYTHON_SCRIPT_NODE',
	RCV_INP = 'RCV_INP',
	RCV_INP_ENTITY = 'RCV_INP_ENTITY',
	RNDM_MSG = 'RNDM_MSG',
	SCRIPT_NODE = 'SCRIPT_NODE',
	SELECT_LANGUAGE = 'SELECT_LANGUAGE',
	SEND_AUDIO = 'SEND_AUDIO',
	SEND_CAROUSEL = 'SEND_CAROUSEL',
	SEND_DOCUMENT = 'SEND_DOCUMENT',
	SEND_IMAGE = 'SEND_IMAGE',
	SEND_MSG = 'SEND_MSG',
	SEND_MSG_OPTNS = 'SEND_MSG_OPTNS',
	SEND_VIDEO = 'SEND_VIDEO',
	SET_USER_ATTRIBUTES = 'SET_USER_ATTRIBUTES',
	SHOPIFY_NODE = 'SHOPIFY_NODE',
	SLIDER_NODE = 'SLIDER_NODE',
	START = 'START',
	SUBSCRIBE_CAMPAIGN = 'SUBSCRIBE_CAMPAIGN',
	TEMPLATE_MESSAGE = 'TEMPLATE_MESSAGE',
	UNSUBSCRIBE_CAMPAIGN = 'UNSUBSCRIBE_CAMPAIGN',
	WEB_VIEW_NODE = 'WEB_VIEW_NODE',
	WHATSAPP_COMMERCE_NODE = 'WHATSAPP_COMMERCE_NODE',
	ZAPIER = 'ZAPIER',
	RECURRING_NOTIFICATION_NODE = 'RECURRING_NOTIFICATION_NODE',
	WA_PAYMENT_NODE = 'WA_PAYMENT_NODE',
	EXTERNAL_INTEGRATION_NODE = 'EXTERNAL_INTEGRATION_NODE',
	WA_FLOW_NODE = 'WA_FLOW_NODE',
}

export enum FLOW_OPTIONS {
	DO_NOT_SEND_MESSAGE = 'DO_NOT_SEND_MESSAGE',
	SEND_MESSAGE = 'SEND_MESSAGE',
	SEND_MESSAGE_WITH_OPTIONS = 'SEND_MESSAGE_WITH_OPTIONS',
}

export enum VIEW_LOG_EVENTS {
	ONLINE_OFFLINE_EVENT = 'ONLINE_OFFLINE_EVENT',
}

export enum LAYOUT_COMPONENTS {
	LAYOUT_HEADER = 'LayoutHeader',
	LAYOUT_CONTENT = 'LayoutContent',
	LAYOUT_RIGHT_PANEL = 'LayoutRightPanel',
	LAYOUT_FOOTER = 'LayoutFooter',
}

export enum THEME_VALUES {
	LIGHT = 'LIGHT',
	DARK = 'DARK',
	SYSTEM = 'SYSTEM',
}

export enum IMPORT_CONTACT_STATUS {
	SUCCESS = 'SUCCESS',
	IN_PROGRESS = 'IN_PROGRESS',
	FILE_SIZE_EXCEEDED = 'FILE_SIZE_EXCEEDED',
}

export enum THEME_COLOR_OPTIONS {
	DEFAULT = 'DEFAULT',
	EARTHEN_HUES = 'EARTHEN_HUES',
	SKY_LIGHT = 'SKY_LIGHT',
}

export enum THEME_COLOR_OPTIONS_NAME {
	DEFAULT = 'Default',
	EARTHEN_HUES = 'Earthen Hues',
	SKY_LIGHT = 'Sky Light',
}

export enum SHOPIFY_PAYMENT_STATUS {
	accepted = 'ACCEPTED',
	declined = 'DECLINED',
}

export enum CHANNELS {
	GBM = 'gbm',
	EMAIL = 'email',
	FACEBOOK = 'facebook',
	FACEBOOK_ALL_CAPS = 'FACEBOOK',
	FACEBOOK_CAPITALISED = 'Facebook',
	WHATSAPP = 'whatsapp',
	SLACK = 'slack',
	TWITTER = 'twitter',
	WECHAT = 'wechat',
	SKYPE = 'skype',
	SKYPE_FOR_BUSINESS = 'skypeforbusiness',
	KIK = 'kik',
	VIBER = 'viber',
	TEAMS = 'teams',
	LINE = 'line',
	TELEGRAM = 'telegram',
	MOBILE = 'mobile',
	WEB = 'web',
	INSTAGRAM = 'instagram',
	INSTAGRAM_ALL_CAPS = 'INSTAGRAM',
	INSATAGRAM_CAPITALISED = 'Instagram',
	MSFT = 'msft',
	IM = 'im',
	API_TRIGGER = 'apitrigger',
	NEXMO = 'nexmo',
	DIALOG360 = 'dialog360',
	COMMON_WHATSAPP = 'common_whatsapp',
	SMS = 'sms',
	ALL_CHANNELS = 'all_channels',
	WHATSAPP_ALL_CAPS = 'WHATSAPP',
	WHATSAPP_TITLE_CASE = 'Whatsapp',
	RCS = 'rcs',
}

export enum WA_RAPIDPLAN {
	ENGATI_WA_RAPID = 'ENGATI_WA_RAPID',
}

export enum BOT_VERSION {
	BOT_VERSION1 = 1,
	BOT_VERSION2 = 2,
}

export enum MESSAGE_STATUS {
	READ = 'READ',
	DELIVERED = 'DELIVERED',
	SENT = 'SENT',
	FAILED = 'FAILED',
}

export enum ValidationTypes {
	OBJECT,
}

export enum ICON_TO_RENDER {
	READ = '/shared/icons/Messages/doubleTickRead.png',
	DELIVERED = '/shared/icons/Messages/doubleTickUnread.png',
	SENT = '/shared/icons/Messages/singleTick.png',
	FAILED = '/shared/icons/Messages/error.png',
}

export enum BROADCAST_STATUS {
	SENT = 'SENT',
	IN_PROGRESS = 'IN_PROGRESS',
	PUBLISHED = 'PUBLISHED',
	BATCHING = 'BATCHING',
	RETRYING = 'RETRYING',
	RETARGET_SEGMENT_CREATION = 'RETARGET_SEGMENT_CREATION',
	FAILED = 'FAILED',
	STOPPING = 'STOPPING',
	STOPPED = 'STOPPED',
	SUBMITTED = 'SUBMITTED',
	PREPARING = 'PREPARING',
	SCHEDULED = 'SCHEDULED',
}

export enum OPT_OUT_FIELDS {
	BUTTON_LIST = 'buttonList',
	OPT_OUT_CHECKBOX = 'optOutCheckbox',
}

export enum RETRY_FIELD {
	RETRY_TOGGLE = 'retryToggle',
}

export enum OPT_OUT_BUTTON_OPTION {
	NONE = 'None',
	QUICK_REPLY = 'QUICK_REPLY',
}

export enum segmentOptions {
	RETARGET = 'RETARGET',
	SEGMENT = 'SEGMENT',
}

export enum BROADCAST_USAGE {
	RETRY_BROADCAST = 'retryBroadcast',
	QUERY_TYPE = 'FAILED_BROADCAST_RETRY_QUERY',
}

export enum EMAIL_ATTACHMENT_TYPE {
	'INLINE_IMAGE' = 'INLINE_IMAGE',
	'EMAIL_ATTACHMENT' = 'EMAIL_ATTACHMENT',
}

export enum bodyType {
	JSON = 'JSON',
	URL_ENCODED = 'formUrlencoded',
	APPLICATION_JSON = 'application/json',
	APPLICATION_URL_ENCODED = 'application/x-www-form-urlencoded',
	NONE = 'None',
}

export enum content {
	CONTENT_TYPE = 'Content-Type',
}

export enum DATE_FILTER {
	TODAY = 'TODAY',
	LAST_7_DAYS = 'LAST_7_DAYS',
	LAST_30_DAYS = 'LAST_30_DAYS',
}

export enum USERS_TAB {
	LIST = 'list',
	SEGMENT = 'segments',
	EXPORT = 'export-history',
	IMPORT = 'import-history',
}

export enum NEW_AGENT_PERFORMANCE_TAB {
	QUALITY = 'quality',
	PRODUCTIVITY = 'productivity',
	PERFORMANCE = 'performance',
}

export enum EXPORT_AGENT_ANALYTICS {
	AGENT_PERFORMANCE = 'AGENT_PERFORMANCE',
	AGENT_QUALITY = 'AGENT_QUALITY',
	AGENT_PRODUCTIVITY = 'AGENT_PRODUCTIVITY',
}

export enum CUSTOMERS_TAB {
	CTWA_SEGMENT = 'ctwa-segment',
	SHOPIFY_SEGMENT = 'shopify-segment',
	SYSTEM_SEGMENT = 'system-segment',
	CUSTOM_SEGMENT = 'custom-segment',
	EXPORT = 'export-history',
}

export enum CONTACT_TAB {
	LIST = 'list',
	EXPORT = 'export-history',
	IMPORT = 'import-history',
}

export enum MARKETING_CAMPAIGNS {
	EXECUTION = 'execution',
	ENGAGEMENT = 'engagement',
	CONVERSION = 'conversion',
}

export enum MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES {
	CONVERSION_ENABLED = 'CONVERSION_ENABLED',
	CONVERSION_DISABLED = 'CONVERSION_DISABLED',
	PARTNER = 'PARTNER',
}

export enum EXECUTION_TAB {
	BROADCAST = 'broadcast',
	CTWA = 'ctwa',
	CAMPAIGN_AUTOMATION = 'campaign-automation',
}

export enum ENGAGEMENT_TAB {
	SUMMARY = 'summary',
	BROADCAST = 'broadcast',
	CTWA = 'ctwa',
}

export enum DAYS_FILTER {
	DAYS_7 = 'DAYS_7',
	DAYS_28 = 'DAYS_28',
}

export enum ADVANCED_TAB {
	VOICE = 'voice',
	FORMATTING = 'formatting',
	SECURITY = 'security',
}

export enum QUICK_NAVIGATOR_TAB {
	BASIC_SETUP = 'basic-setup',
	FEATURE_EXPLORER = 'feature-explorer',
}

export enum SHOPIFY_DASHBOARD_TAB {
	PERFORMANCE_DASHBOARD = 'performance-dashboard',
	REVENUE_DASHBOARD = 'revenue-dashboard',
	SELLERS_ASSIST = 'insights',
}

export enum SHOPIFY_CONVERSION {
	TRIGGERS = 'triggers',
	POPUP_MODAL_CONFIG_SETUP = 'popup-modal-config-setup',
}

export enum SEGMENT_TYPES {
	CTWA = 'CTWA',
	CUSTOM = 'CUSTOM',
	SYSTEM = 'SYSTEM',
	SHOPIFY_EXTERNAL = 'SHOPIFY_EXTERNAL',
	DEFAULT_NULL = 'DEFAULT_NULL',
}

export enum SETUP_DETAILS {
	STORE_SETUP = 'Store Setup',
	PRODUCTS = 'Products & Collections',
	CUSTOMER_SEGMENT = 'Customer Segment',
	CONFIGURE_LIVECHAT = 'Configure Live Chat',
	MANAGE_TEAMS = 'Manage Teams',
	ABONDENED_CHECKOUT = 'Abandoned Checkout Recovery',
	COD_TO_PREPAID = 'COD To Prepaid Conversion',
	BROADCAST = 'Broadcast',
	RETARGET_CUSTOMERS = 'Retarget',
	PRODUCT_RECOMMENDATION = 'Product Recommendation',
	REORDER_CAMPAIGN = 'Re-Order Campaign',
	FEEDBACK_CAMPAIGN = 'Feedback Campaign',
	CONVERT_LEADS = 'Convert Leads',
	RETAIN_CUSTOMERS = 'Retain Customers',
	CHANNEL_DEPLOYMENT = 'Channel Deployment',
	CONNECT_STORE = 'Connect your Store',
	CONNECT_WHATSAPP = 'Connect your WhatsApp API',
	RECHARGE_WALLET = 'Recharge your Wallet',
	ORDER_NOTIFICATIONS = 'Order Notifications',
	ENABLE_AUTOMATED_MESSAGES = 'Enable Automated Messages',
	CONNECT_APPS = 'Connect Apps',
	ANALYTICS = 'Analytics',
	WHATSAPP_MARKETING = 'WhatsApp Marketing',
	CREATE_TEMPLATE_MESSAGE = 'Step 1: Create Template message',
	ADD_TARGET_AUDIENCE = 'Step 2: Add Target Audience',
	CREATE_NEW_BROADCAST = 'Step 3: Create New Broadcast',
	MORE_INTEGRATIONS_AND_CHANNELS = 'More Integrations & Channels',
	ACCOUNT_SETUP = 'Account Setup',
	CLICKED_INTGERATIONS = 'Integrate with your favourite applications',
	CLICKED_DEPLOYMENT = 'Omni Channel Customer Engagement',
	INTEGRATE_SHIPROCKET = 'Connect with Shiprocket',
	INTEGRATE_GOOGLESHEETS = 'Connect with Google Sheets',
	BROADCAST_ANALYTICS = 'Detailed Broadcast Analytics',
	LIVECHAT_SETTINGS = 'Live Chat Inbox Settings',
}

export enum WA_NOTIFICATIONS_EVENTS {
	PREPAID_EVENT = 'ORDER_UPDATE_NOTIFICATION',
	ABONDENED_EVENT = 'ABANDONMENT_NOTIFICATION',
}

export enum NAVIGATION_STATES {
	EXPAND_COD_TO_PREPAID_PANEL = 'expandCodToPrepaidPanel',
	EXPAND_ABANDONED_CHECKOUT_PANEL = 'expandAbandonedCheckoutPanel',
	EXPAND_ABANDONED_CART_PANEL = 'expandAbandonedCartPanel',
	EXPAND_ORDER_NOTIFICATIONS_PANEL = 'expandOrderNotificationsPanel',
	EXPAND_BROWSE_ABANDONMENT_PANEL = 'expandBrowseAbandonmentPanel',
	EXPAND_OUT_OF_STOCK_PRODUCTS_PANEL = 'expandOutOfStockProductsPanel',
}

export enum ACCOUNT_ENFORCED_USAGE {
	ACCOUNT_ENFORCED = 'accountEnforced',
	QUERY_TYPE = 'ACCOUNT_ENFORCED_QUERY',
}

export enum ROUTING_OPERATOR_TYPES {
	ROUTING_BOOLEAN = 'ROUTING_BOOLEAN',
	ROUTING_SELECT = 'ROUTING_SELECT',
	ROUTING_TEXT = 'ROUTING_TEXT',
	ROUTING_NUMBER = 'ROUTING_NUMBER',
	ROUTING_DATE = 'ROUTING_DATE',
}

export enum CTA_BUTTONS {
	URL = 'URL',
	PHONE_NUMBER = 'PHONE_NUMBER',
}

export enum SMS_PROVIDERS {
	SMS_CLICKATELL = 'SMS Clickatell',
	SMS_TWILIO = 'SMS Twilio',
	provider = 'provider',
}

export enum SMS_PROVIDER_KEYS {
	CLICKATELL = 'SMS_CLICKATELL',
	TWILIO = 'SMS_TWILIO',
}

export enum DEVICE_TYPE {
	WEBSITE = 'WEBSITE',
	MOBILE = 'MOBILE',
}

export enum EXECUTED_BY {
	AGENT = 'AGENT',
	SYSTEM = 'SYSTEM',
}

export enum BROADCAST_TYPE {
	COPY = 'copy',
	RESUME = 'resume',
	RETARGET = 'retarget',
	CREATE = 'create',
	EDIT = 'edit',
	CONTACT = 'contact',
	CUSTOMER_SEGMENT = 'customerSegment',
	TEMPLATE = 'template',
	ADS_SEGMENT = 'adsSegment',
}

export enum BROADCAST_TARGET_GROUP {
	USER_SEGMENT_V2 = 'USER_SEGMENT',
	CONTACT_LIST = 'CONTACT_LIST',
	USER_SEGMENT_V1 = 'SEGMENT',
}

export enum BROADCAST_MESSAGE_TYPES {
	DIRECT = 'DIRECT',
	OPTIONS = 'OPTIONS',
	FLOW = 'FLOW',
	WHATSAPP_TEMPLATE = 'WHATSAPP_TEMPLATE',
	TEMPLATE = 'TEMPLATE',
	MESSAGE_V2 = 'MESSAGE',
	MESSAGE_WITH_OPTIONS_V2 = 'MESSAGE_WITH_OPTIONS',
	RICH_CARD = 'RICH_CARD',
}

export enum COLLECTIONS_DISPLAY_TYPE {
	OPTIONS = 'OPTIONS',
	CAROUSEL = 'CAROUSEL',
}

export enum LOCAL_STORAGE_VALUES {
	SET_SANDBOX_ENABLED = 'setSandboxEnabled',
	SET_DIALOG360_ENABLED = 'setProviderAsDialog360',
}

export enum WA_COMMERCE_ACTIONS {
	SEARCH_PRODUCTS = 'SEARCH_PRODUCTS',
	DISPLAY_PRODUCTS = 'DISPLAY_PRODUCTS',
	DISPLAY_COLLECTIONS = 'DISPLAY_COLLECTIONS',
}

export enum SCHEDULE_TYPES {
	NOW = 'now',
	LATER = 'later',
}

export enum CUSTOM_AUDIENCE_FIEDS {
	DATA_POINTS = 'dataPoints',
	ACTIVE_IN = 'activeIn',
	MESSAGED_BUSINESS = 'messagedBusiness',
	POST_INTERACTION = 'postInteraction',
}

export enum CUSTOM_AUDIENCE_ACTIVE_IN {
	WEEK1 = 'last7Days',
	MONTH1 = 'last30Days',
	MONTH3 = 'last90Days',
}

export enum CUSTOM_AUDIENCE_DATA_POINTS {
	EMAIL = 'email',
	PHONE_NO = 'contact_number',
	FB_ID = 'user_id',
	FIRST_NAME = 'first_name',
	LAST_NAME = 'last_name',
	CHANNELS = 'channels',
	LINKED_USERS = 'linked_users',
	USERNAME = 'username',
}
export enum AGENT_ASSISTANCE_FEEDBACK_TABS {
	OVERALL_FEEDBACK = 'overall_feedback',
	AGENT_WISE_FEEDBACK = 'agent_wise_feedback',
}

export enum AttributePreference {
	USER = 'USER',
	CONTACT = 'CONTACT',
}

export enum ENTITLEMENTS {
	ENABLE_RECURRING_NOTIFICATION_NODE = 'enable_recurring_notification_node',
	ENABLE_TEMPLATE_MESSAGE = 'enable_template_message',
	ENABLE_WHATSAPP_COMMECE = 'enable_whatsapp_commerce',
	IS_WA_COMMERCE_CONFIGURED = 'is_wa_commerce_configured',
}

export enum FILE_EXTENSIONS {
	CSV = 'csv',
	XLSX = 'xlsx',
}

export enum MIME_TYPES {
	CSV = 'text/csv',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum USER_LIST_TAB {
	GLOBAL_USER_ID = 'global_user_id',
}

export enum LLM_PROVIDERS {
	OPENAI = 'OPENAI',
	AZURE_OPENAI = 'AZURE_OPENAI',
	GOOGLE_PALM = 'GOOGLE_PALM',
}

export enum FormNodeFieldTypes {
	TEXT = 'text',
	NUMBER = 'number',
	EMAIL = 'email',
	DATE = 'date',
	MOBILE = 'mobile',
	OBJECT = 'object',
	SINGLE_SELECT = 'SINGLE_SELECT',
}

export enum importHistoryFileObjectKeys {
	TOKEN = 'token',
	ERROR_TOKEN = 'errorToken',
	FILE_NAME = 'fileName',
	STATUS = 'status',
	SUCCESS_TOKEN = 'successToken',
	SUCCESS_FILE_NAME = 'successFileName',
}

export enum PathBuilderPath {
	WELCOME_NEW_USER = 'welcome_',
	GREET_RETURNING_USER = 'greetings_',
	DEFAULT_MESSAGE = 'default_fallback_msg_',
	POST_RESOLUTION = 'livechat_post_resolution_',
	AGENT_UNAVAILABLE = 'agent_unavailable_msg_',
}

export enum QuickNavigatorJourney {
	TAKE_TUTORIAL = 'takeTutorialButton',
	BASE_PARAM = 'waQuickGuideJourney',
	CONNECT_WHATSAPP_API = 'connectWhatsappApi',
	RECHARGE_WALLET = 'rechargeWallet',
	LIVE_CHAT = 'liveChat',
	WHATSAPP_MARKETING = 'whatsappMarketing',
	ANALYTICS = 'analytics',
	WORKFLOW_AUTOMATION = 'workflowAutomation',
	INTEGRATIONS_AND_CHANNELS = 'integrationAndChannels',
}

export enum WA_ACCELERATOR_PQL_STAGES {
	STEP_1 = 'Step 1 - Complete',
	STEP_2 = 'Step 2 - Complete',
	REGISTRATION = 'Registration Complete',
	BROADCAST_SENT = 'Broadcast Sent',
	LIVECHAT_INITIATED = 'Livechat Initiated',
	BROADCAST_AND_LIVECHAT = 'Broadcast Sent and Livechat Initiated',
	UPGRADE_REQUESTED = 'Upgrade Requested',
	PAYMENT_INITIATED = 'Payment Initiated',
	PAYMENT_ABANDONED = 'Payment Abandoned',
	PAYMENT_SUCCESS = 'Paid Plan Upgraded',
	PAYMENT_FAILED = 'Payment Failed',
}

export enum WA_ACCELERATOR_JOURNEY_PHASE {
	COMPLETED_QUICK_GUIDE_JOURNEY = 'completedQuickGuideJourney',
	COMPLETED_MESSAGE_LIST_JOURNEY = 'completedMessageListJourney',
	STEP_COMPLETED = 'stepCompleted',
	VISITED_TEMPLATE_LIBRARY = 'visitedTemplateLibraryPage',
	VISIT_PATH_BUILDER = 'visitedPathBuilder',
	FIRST_BROADCAST_SENT = 'firstBroadcastSent',
	CAPTURED_LEADS = 'capturedLeads',
	VISITED_BROADCAST_HISTORY = 'visitedBroadcastHistoryPage',
	STARTED_TRIAL_CONVERSATION = 'startedTrialConversation',
	VISITED_AGENT_PERFORMANCE = 'visitedAgentPerformancePage',
	OPTED_FOR_WA_UPDATES = 'optedForWAUpdates',
	BROADCAST_NUDGE_SHOWN_AT = 'broadcastNudgeShownAt',
	LIVECHAT_NUDGE_SHOWN_AT = 'livechatNudgeShownAt',
	PLAN_UPGRADE_NUDGE_SHOWN_AT = 'planUpgradeNudgeShownAt',
}

export enum WA_ACCELERATOR_SETUP {
	VIEW_TEMPLATE_LIBRARY = 'viewTemplateLibrary',
	SEND_BROADCAST = 'sendBroadcast',
	LEADS_CAPTURE = 'leadsCapture',
	BROADCAST_PERFORMANCE = 'viewBroadcast',
	START_CONVERSATIONS = 'startCoversations',
	CHAT_PERFORMANCE = 'viewLivechatPerformance',
	BOT_ANALYTICS = 'viewBotAnalytics',
	CREATE_TEMPLATE_MESSAGE = 'createTemplateMessage',
	ADD_TARGET_AUDIENCE = 'addTargetAudience',
	CREATE_NEW_BROADCAST = 'createNewBroadcast',
	CLICKED_INTGERATIONS = 'clickedIntegrations',
	CLICKED_DEPLOYMENT = 'clickedDeployment',
	CONVERSATION_BUILDER = 'conversationBuilder',
	SUPPORT_AUTOMATION = 'supportAutomation',
	AUTOMATE_NOTIFICATION = 'automateNotification',
	GENERATIVE_AI = 'generativeAi',
	CREATE_CAROUSEL_TEMPLATE = 'createCarouselTemplate',
	WHATSAPP_COMMERCE = 'whatsAppCommerce',
	WHATSAPP_PAY = 'whatsAppPay',
	WHATSAPP_FLOWS = 'whatsAppFlows',
	CONNECT_WHATSAPP = 'connectWhatsapp',
	RECHARGE_WALLET = 'rechargeWallet',
	VIEW_LIVECHAT_SETTINGS = 'viewLivechatSettings',
}

export enum WL_ACCELERATOR_FEATURE_EXCLUSION {
	AI_CHATBOT = 'AI Chatbot',
}

export enum ONBOARDING_TYPE {
	NEW_USER = 'NEW_USER',
	EXISITNG_USER = 'EXISITNG_USER',
}

export enum ENVIRONMENT_TAGS {
	STAGING = 'STAGING',
	PRODUCTION = 'PRODUCTION',
}
export enum EXTERNAL_INTEGRATION_PROVIDER {
	INTEGRY = 'Integry',
}

export enum UpdateOperationType {
	IMPORT_COUNT = 'IMPORT_COUNT',
	SUBMISSION_COUNT = 'SUBMISSION_COUNT',
}

export enum FEEDBACK_REPORT_EXPORT_WORKFLOW {
	BOT = 'BOT',
	AGENT = 'AGENT',
}

export enum SHOPIFY_DASHBOARD {
	SHOPIFY_DASHBOARD_EMPTY = 'SHOPIFY_DASHBOARD_EMPTY',
	SHOPIFY_DASHBOARD = 'SHOPIFY_DASHBOARD',
}

export enum FEATURE_TYPE {
	CAMPAIGN = 'CAMPAIGN',
	BROADCAST = 'BROADCAST',
}

export enum BROADCAST_OBJECTIVE {
	INCREASE_WEBSITE_VISITS = 'INCREASE WEBSITE VISITS',
	PROMOTE_OFFERS = 'PROMOTE OFFERS',
	INCREASE_BRAND_AWARENESS = 'INCREASE BRAND AWARENESS',
	ENGAGE_CUSTOMERS = 'ENGAGE CUSTOMERS',
	INCREASE_SALES = 'INCREASE SALES',
	CAPTURE_LEAD_DETAILS = 'CAPTURE LEAD DETAILS',
	RETARGET_CUSTOMERS = 'RETARGET CUSTOMERS',
	NOTIFICATIONS = 'NOTIFICATIONS',
	OTHERS = 'OTHERS',
}

export enum PlanDuration {
	MONTHLY = 'MONTHLY',
	QUARTERLY = 'QUARTERLY',
	HALF_YEARLY = 'HALF_YEARLY',
	YEARLY = 'YEARLY',
}

export enum RESPONSE_STATUS {
	notAuthorized = 'not_authorized',
	connected = 'connected',
}

export enum EngatiEmails {
	BILLING_EMAIL = 'billing@engati.com',
	SUPPORT_EMAIL = 'support@engati.com',
}

export enum FeatureConfiguratorOrderNotificationFields {
	ORDER_FULFILMENT = 'ecomOrderCodConfirmation',
	ORDER_COD_CONFIRMATION = 'ecomOrderFulfilment',
	ORDER_PREPAID_CONFIRMATION = 'ecomOrderPrepaidConfirmation',
	ORDER_CANCELLATION = 'ecomOrderCancellation',
}

export enum FeatureConfiguratorShipRocketFields {
	SHIPROCKET_ORDER_CANCELLATION = 'shiprocketOrderCancellation',
	SHIPROCKET_ORDER_READY_TO_SHIP = 'shiprocketOrderReadyToShip',
	SHIPROCKET_ORDER_SHIPPED = 'shiprocketOrderShipped',
	SHIPROCKET_ORDER_IN_TRANSIT = 'shiprocketOrderInTransit',
	SHIPROCKET_ORDER_OUT_FOR_DELIVERY = 'shiprocketOrderOutForDelivery',
	SHIPROCKET_ORDER_PARTIALLY_DELIVERED = 'shiprocketOrderPartiallyDelivered',
	SHIPROCKET_ORDER_DELIVERED = 'shiprocketOrderDelivered',
	SHIPROCKET_ORDER_OUT_FOR_PICKUP = 'shiprocketOrderOutForPickup',
	SHIPROCKET_ORDER_PICKED = 'shiprocketOrderPicked',
}

export enum FeatureConfiguratorAbandonedCheckoutFields {
	FIRST_ABANDONED_CHECKOUT = 'firstAbandonedCheckout',
	SECOND_ABANDONED_CHECKOUT = 'secondAbandonedCheckout',
	THIRD_ABANDONED_CHECKOUT = 'thirdAbandonedCheckout',
}

export const FeatureConfiguratorBooleanFields = {
	...FeatureConfiguratorOrderNotificationFields,
	...FeatureConfiguratorShipRocketFields,
	...FeatureConfiguratorAbandonedCheckoutFields,
};

export enum FieldTypes {
	SELECT = 'select',
	RADIO = 'RADIO',
	TEXT = 'Text',
	TAGS = 'Tags',
	TEXTAREA = 'textarea',
	CHECKBOX = 'CHECKBOX',
}

export enum WHATSAPP_DEPLOYMENT_ERROR {
	NUMBER_ALREADY_CONFIGURED = 'Number already configured with sandbox',
	INVALID_ACCOUNT_DETAILS_RESPONSE = 'Cannot determine base url cause of invalid response from account details API',
	INVALID_HOSTING_TYPE = 'Cannot determine base url cause of invalid hosting type parameter',
	INVALID_PHONE_NUMBER = 'Invalid Phone number provided',
	INVALID_API_KEY = 'Invalid API Key provided',
}

export enum WLExternalIntegrationExclusionList {
	MAILCHIMP = 'Mailchimp',
}

export enum STEPTYPE {
	BUTTON = 'Button',
	LINK = 'link',
}
