/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import ENGTImage from 'components/UI/ENGTImage/ENGTImage';

import { TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { componentTypes, CTA_BUTTONS } from 'shared/enum';
import {
	decodeAndSanitizeInput,
	getSizeInMbOrKb,
	hexToRgb,
	templateMessageformFieldIds,
	waRtlLanguageCodeMapper,
} from 'shared/helpers';
import { processQuillContent } from 'shared/helpers/quillHelper';
import { getHtmlFormatText } from 'shared/helpers/templateHelpers';
import ic_document from 'shared/icons/ic_document.svg';
import ic_pdf_upload from 'shared/icons/ic_pdf_upload.svg';
import LeftArrowIcon from 'shared/icons/LeftArrowIcon';
import LTOIcon from 'shared/icons/LTOIcon.svg';
import RightArrowIcon from 'shared/icons/RightArrowIcon';
import VideoIcon from 'shared/icons/VideoIcon';
import { componentFormat } from 'shared/whatsappTemplateHelpers';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './TemplatePreviewCards.scss';

const TemplatePreviewCards = ({
	carouselPreviewCardsData,
	isLTOComponent = false,
	isAuthenticationTemplate = false,
	securityDisclaimer = false,
	codeExpiration = '',
	otpExpirationTime = '',
	bubbleText,
	header = {},
	offerTitle = '',
	expirationTime = '',
	offerCode = '',
	expirationToggle = false,
	footer = '',
	buttons = [],
	bodyText,
	variables = {},
	setSeeAllOptionsButtonClicked,
	setButtonsArray,
	language,
}: any) => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['pages']);
	const expirationTimeLabel = t('common:standardDateWithoutYearFormatter', {
		date: expirationTime * 1000,
	});
	const methods = useFormContext();
	const { getValues, watch } = methods || {};
	const templateLanguage = language || getValues?.(templateMessageformFieldIds.LANGUAGE)?.[0]?.id;
	const textAlignCss =
		Object.keys(waRtlLanguageCodeMapper()).findIndex((lang) => lang === templateLanguage) === -1 ? 'left' : 'right';

	const [showAllButtons, setShowAllButtons] = useState<boolean>(false);

	useEffect(() => {
		if (buttons.length > TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT) {
			!showAllButtons && setShowAllButtons(false);
		}
	}, [buttons]);

	const arrowCss: any = {
		fontSize: 0,
		lineHeight: 0,
		position: 'absolute',
		top: '110%',
		right: '15%',
		width: '0.35rem',
		height: '1.25rem',
		padding: 0,
		transform: 'translate(-50%, -50%)',
		cursor: 'pointer',
		color: 'transparent',
		border: 'none',
		outline: 'none',
		borderRadius: '50%',
	};

	const containerCss = {
		textAlign: textAlignCss,
		width: '12rem !important',
		backgroundColor: theme.colors.primaryB,
		borderColor: theme.colors.accent400,
		borderWidth: '0.0625rem',
		borderStyle: 'solid',
		borderTopRightRadius: '0.5rem',
		borderTopLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		color: theme.colors.primaryA,
		marginRight: '0.25rem',
		marginTop: '0.5rem',
	};

	const descriptionCss = {
		minHeight: '1rem',
		height: 'auto',
		whiteSpace: 'pre-line',
		wordWrap: 'break-word',
		fontSize: '0.875rem',
		lineHeight: '1rem',
		fontWeight: 400,
		textAlign: textAlignCss,
		paddingBottom: '0.5rem',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	};
	const interectiveWrapper = {
		display: 'flex',
		fontFamily: 'helvetica',
		flexDirection: 'column',
		flex: '1 1 100%',
		overflowY: 'auto',
	};
	const interectiveCss = {
		width: '12rem !important',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.primaryB,
		borderColor: theme.colors.accent400,
		borderWidth: '0.0625rem',
		borderStyle: 'solid',
		borderTopRightRadius: '0.5rem',
		borderTopLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		color: theme.colors.primaryA,
		marginLeft: '0.5rem',
		marginTop: '0.5rem',
		paddingLeft: '0.5rem',
		marginRight: '0.5rem',
		marginBottom: '0.5rem',
		paddingRight: '0.5rem',
		paddingTop: '0.5rem',
		textAlign: textAlignCss,
	};

	const detailDivCss = {
		paddingTop: '0.25rem',
		paddingRight: '0.25rem',
		paddingBottom: '0.25rem',
		paddingLeft: '0.25rem',
		borderTopRightRadius: '0.5rem',
		borderTopLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
	};

	const buttonCss = {
		lineHeight: '1rem',
		fontWeight: 500,
		textAlign: 'center',
		paddingTop: '0.375rem',
		paddingBottom: '0.5rem',
		color: theme.whatsappBtnColor,
		borderTopWidth: '0.0625rem',
		borderTopStyle: 'solid',
		borderTopColor: theme.navigationBorderColor,
		borderBottomRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		cursor: 'pointer',
		fontSize: '0.875rem',
		wordWrap: 'break-word',
	};

	const codeExpirationCss = {
		color: `${theme.colors.accent400}`,
		whiteSpace: 'pre-line',
		wordWrap: 'break-word',
		paddingBottom: '0.5rem',
		fontSize: '0.5rem',
	};

	const variableReplacer = (parameters: Array<string>, text: string) => {
		let sanitisedString = text;

		if (parameters?.length) {
			parameters.map((parameterValue: string, index: number) => {
				sanitisedString = sanitisedString.replaceAll(`{{${index + 1}}}`, parameterValue);
			});
		}

		if (isAuthenticationTemplate) {
			const parameters = variables?.verification_code;
			if (parameters !== null && parameters !== undefined) {
				sanitisedString = sanitisedString.replace(`{{VERIFICATION_CODE}}`, parameters);
			}
		}

		return processQuillContent(sanitisedString) ?? '';
	};

	const replaceVariables = (text: string, component: string) => {
		const parameters = variables?.[component];

		return variableReplacer(parameters, text);
	};

	let codeExpirationText = t('pages:broadcast.messageTemplates.createTemplate.authentication.codeExpirationText')
		.replace(/{openBrace}/g, '{')
		.replace(/{closeBrace}/g, '}');

	if (otpExpirationTime && typeof otpExpirationTime !== 'object') {
		codeExpirationText = codeExpirationText.replace(
			'{{NUM_MINUTES}}',
			`${otpExpirationTime} ${t('pages:broadcast.messageTemplates.createTemplate.authentication.minutes')}`
		);
	}

	const replaceCarouselVariables = (text: string, component: string, index: number) => {
		const parameters = variables?.['carousel']?.[index]?.[component];

		return variableReplacer(parameters, text);
	};

	const CustomNextArrow = (props: any) => {
		const { className, onClick } = props;
		const isArrowVisible = className.indexOf('disabled') === -1;

		return isArrowVisible ? (
			<div onClick={onClick} style={arrowCss}>
				<RightArrowIcon
					width='2rem'
					height='1rem'
					viewX={-8}
					viewBoxWidth={15}
					fillColor={theme.colors.primaryA}
				/>
			</div>
		) : (
			<div style={arrowCss}>
				<RightArrowIcon
					width='2rem'
					height='1rem'
					viewX={-8}
					viewBoxWidth={15}
					fillColor={theme.colors.contentStateDisabled}
				/>
			</div>
		);
	};

	const CustomPrevArrow = (props: any) => {
		const { className, onClick } = props;
		const isArrowVisible = className.indexOf('disabled') === -1;

		return isArrowVisible ? (
			<div onClick={onClick} style={{ ...arrowCss, left: '12rem' }}>
				<LeftArrowIcon
					width='2rem'
					height='1rem'
					viewX={-5}
					viewBoxWidth={15}
					fillColor={theme.colors.primaryA}
				/>
			</div>
		) : (
			<div style={{ ...arrowCss, left: '12rem' }}>
				<LeftArrowIcon
					width='2rem'
					height='1rem'
					viewX={-5}
					viewBoxWidth={15}
					fillColor={theme.colors.contentStateDisabled}
				/>
			</div>
		);
	};

	const rendercarousel = (carousel: IObjProps, index: number) => {
		const description = carousel.body.bodyText || 'Card Body Text...';
		const button = carousel?.buttons?.[0]?.label || 'Button';
		const button2 = carousel?.buttons?.[1]?.label;

		return (
			<div className={css({ ...containerCss, ...detailDivCss })} key={carousel.id}>
				<div
					className={css({
						height: '5.125rem',
						paddingBottom: '0.5rem',
					})}
				>
					{carousel?.header?.headerFormat.toUpperCase() === componentFormat.image && (
						<img
							src={
								carousel.header.headerInputValue ||
								'https://fpu.branding-element.com/templates/ImagePlaceholder.png'
							}
							alt='Header Attachment'
							height='100%'
							width='100%'
							className={css({
								objectFit: 'cover',
							})}
						/>
					)}
					{carousel?.header?.headerFormat.toUpperCase() === componentFormat.video &&
						(carousel?.header?.headerInputValue ? (
							<video controls src={carousel.header.headerInputValue} height='100%' width='100%' />
						) : (
							<VideoIcon
								width='100%'
								height='100%'
								fillColor={theme.colors.contentStateDisabled}
								fill='none'
							/>
						))}
				</div>
				<div
					className={`${css(descriptionCss)} carousel-template-preview`}
					dangerouslySetInnerHTML={{
						__html: decodeAndSanitizeInput(
							getHtmlFormatText(replaceCarouselVariables(description, 'body', index))
						),
					}}
				/>
				<div className={css(buttonCss)}>{button}</div>
				{button2 && <div className={css(buttonCss)}>{button2}</div>}
			</div>
		);
	};

	const documentDetails = (values?: any) => (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div
				style={{
					textAlign: 'left',
					fontWeight: 500,
					fontSize: '0.75rem',
					lineHeight: '1rem',
					marginTop: '0.75rem',
					marginLeft: '0.5rem',
				}}
			>
				{values?.[0]?.name ?? values}
			</div>
			<div
				style={{
					textAlign: 'left',
					marginLeft: '0.5rem',
					fontSize: '0.75rem',
					lineHeight: '1rem',
					marginRight: 'auto',
					color: theme.colors.accent50,
				}}
			>
				{(values?.[0]?.size && getSizeInMbOrKb(values[0]?.size)) || ''}
			</div>
		</div>
	);

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		adaptiveHeight: true,
		variableWidth: true,
		slidesToScroll: 1,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
	};

	const renderCarouselCards = () => {
		if (carouselPreviewCardsData.length > 1) {
			return <Slider {...settings}>{carouselPreviewCardsData?.map?.(rendercarousel)}</Slider>;
		}
		if (carouselPreviewCardsData.length === 1) {
			return rendercarousel(carouselPreviewCardsData[0], 0);
		}

		return <></>;
	};

	const renderButton = (index: number) => {
		if (index >= TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT) {
			return false;
		}
		if (
			showAllButtons ||
			index < TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT - 1 ||
			(index === TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT - 1 &&
				!showAllButtons &&
				buttons?.length === TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT)
		) {
			return true;
		}

		return false;
	};

	const modifiedBodyText = securityDisclaimer
		? bodyText + t('pages:broadcast.messageTemplates.createTemplate.authentication.securityDisclaimerPreviewText')
		: bodyText;

	return carouselPreviewCardsData ? (
		<div
			className={css({
				maxWidth: '18.25rem',
				textAlign: textAlignCss,
				boxSizing: 'border-box',
				paddingLeft: '1rem',
			})}
		>
			{bubbleText && (
				<div
					className={`${css({
						...containerCss,
						minHeight: '1rem',
						height: 'auto',
						fontSize: '0.875rem',
						fontWeight: 400,
						whiteSpace: 'pre-line',
						wordWrap: 'break-word',
						paddingRight: '0.5rem',
						paddingTop: '0.5rem',
						paddingBottom: '0.5rem !important',
						paddingLeft: '0.5rem',
						lineHeight: '1.25rem',
					})} carousel-template-preview`}
					dangerouslySetInnerHTML={{
						__html: decodeAndSanitizeInput(
							getHtmlFormatText(replaceVariables(bubbleText, componentTypes.body) || 'Text Message...')
						),
					}}
				/>
			)}
			{renderCarouselCards()}
		</div>
	) : (
		<div className={css(interectiveWrapper)}>
			<div className={css(interectiveCss)}>
				{header && header.type === componentFormat.text && (
					<span
						className={css({
							paddingBottom: '0.5rem',
							fontSize: '0.937rem',
							fontWeight: 500,
							whiteSpace: 'pre-line',
							wordWrap: 'break-word',
						})}
					>
						<b>
							{replaceVariables(
								header.inputValue ||
									t('pages:broadcast.messageTemplates.createTemplate.headerType.headerText'),
								componentTypes.header
							)}
						</b>
					</span>
				)}
				{header && header.type === componentFormat.image && (
					<span className={css({ height: '5rem', paddingBottom: isLTOComponent ? '0.1rem' : '0.5rem' })}>
						<img
							src={
								replaceVariables(header.inputValue, componentTypes.header) ||
								'https://fpu.branding-element.com/templates/ImagePlaceholder.png'
							}
							alt='Template Header'
							height='100%'
							width='100%'
							className={css({
								objectFit: 'cover',
							})}
						/>
					</span>
				)}
				{header && header.type === componentFormat.video && (
					<span
						className={css({
							marginBottom: isLTOComponent ? '0.1rem' : '0.5rem',
							height: '5rem',
							backgroundColor: hexToRgb(theme.colors.backgroundPrimary, 0.5),
							width: '100%',
						})}
					>
						{header.inputValue ? (
							<video
								controls
								src={replaceVariables(header.inputValue, componentTypes.header)}
								height='100%'
								width='100%'
							/>
						) : (
							<VideoIcon
								width='100%'
								height='100%'
								fillColor={theme.colors.contentStateDisabled}
								fill='none'
							/>
						)}
					</span>
				)}
				{header && header.type === componentFormat.documentType && (
					<span className={css({ paddingBottom: '0.5rem', height: '5rem' })}>
						{header.inputValue ? (
							<div
								style={{
									height: '3.75rem',
									display: 'flex',
									marginLeft: '0.25rem',
								}}
							>
								<img
									src={ic_pdf_upload}
									alt='Template Header'
									style={{
										marginTop: '0.25rem',
										objectFit: 'cover',
									}}
								/>
								{documentDetails(getValues?.('mediaDoc') || header.inputValue || '')}
							</div>
						) : (
							<img src={ic_document} alt='Template Header' height='100%' width='100%' />
						)}
					</span>
				)}
				{isLTOComponent && (
					<span
						className={css({
							padding: '0.5rem',
							backgroundColor: hexToRgb(theme.colors.backgroundPrimary, 0.5),
							height: '3rem',
							display: 'flex',
						})}
					>
						<ENGTImage src={LTOIcon} alt='LTO Icon' />
						<Block
							className={css({
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								marginLeft: '0.25rem',
							})}
						>
							<Block
								className={css({
									fontSize: '0.90rem',
									color: theme.colors.primaryA,
									fontWeight: '600',
								})}
							>
								{offerTitle || t('pages:broadcast.messageTemplates.createTemplate.lto.offerTitle')}
							</Block>
							{expirationToggle && (
								<div
									style={{
										marginTop: '0.1rem',
										fontSize: '0.73rem',
										color: hexToRgb(theme.colors.primaryA, 0.75),
									}}
								>
									{expirationTime
										? `${t(
												'pages:broadcast.messageTemplates.createTemplate.lto.endsOn'
											)} ${expirationTimeLabel}`
										: t('pages:broadcast.messageTemplates.createTemplate.lto.OfferExpiryText')}
								</div>
							)}
							<div
								style={{
									marginTop: '0.1rem',
									fontSize: '0.69rem',
									color: hexToRgb(theme.colors.primaryA, 0.42),
								}}
							>
								{offerCode || t('pages:broadcast.messageTemplates.createTemplate.lto.offerCode')}
							</div>
						</Block>
					</span>
				)}
				<span
					className={`${css({
						whiteSpace: 'pre-line',
						wordWrap: 'break-word',
						minHeight: '1rem',
						paddingBottom: '0.5rem !important',
						height: 'auto',
						fontSize: '0.875rem',
					})} carousel-template-preview`}
					dangerouslySetInnerHTML={{
						__html: decodeAndSanitizeInput(
							getHtmlFormatText(replaceVariables(modifiedBodyText, componentTypes.body))
						),
					}}
				/>
				{codeExpiration && (
					<span className={css(codeExpirationCss)}>{replaceVariables(codeExpirationText, '')}</span>
				)}
				{footer && (
					<span
						className={css({
							color: `${theme.colors.accent400} !important`,
							whiteSpace: 'pre-line',
							wordWrap: 'break-word',
							paddingBottom: '0.5rem',
							fontSize: '0.812rem',
						})}
					>
						{replaceVariables(footer, 'footer')}
					</span>
				)}
				{buttons &&
					buttons?.map(
						(button: IObjProps, index: number) =>
							renderButton(index) && (
								<div
									key={`preview-card-${button.id || index}`}
									onClick={() => {
										if (button?.type?.[0]?.id === CTA_BUTTONS.URL) {
											window.open(button?.value, '_blank');
										}
									}}
									className={css({
										...buttonCss,
										paddingLeft: '0rem',
										marginLeft: '0rem',
									})}
								>
									{button.label || 'Button'}
								</div>
							)
					)}
				{buttons.length > TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT && !showAllButtons && (
					<div
						key='see-all-options-btn'
						onClick={() => {
							setShowAllButtons(false);
							setSeeAllOptionsButtonClicked(true);
							setButtonsArray(buttons);
						}}
						className={css({
							...buttonCss,
							paddingLeft: '0rem',
							marginLeft: '0rem',
						})}
					>
						{t('pages:broadcast.messageTemplates.createTemplate.button.seeAllOptions')}
					</div>
				)}
			</div>
		</div>
	);
};

export default TemplatePreviewCards;
