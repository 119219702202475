import {
	DEFAULT_LANGUAGE_CODE,
	GROUP_CONVERSATION_STATES,
	MESSAGE_STATE_MAP,
	MESSAGE_TAB_HEADERS_MAP,
	MESSAGE_TYPE_MAP,
	TRANSLATED_LAST_MESSAGE_STATE,
} from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { messageSort, SORTING } from 'shared/enum';
import { initialsCanvasData, isEmpty, isValidJson, isValidResponseObject } from 'shared/helpers';

import { jitsiActions } from 'store/App/Jitsi';
import { addNotificationAction } from 'store/App/Notifications';
import { playSoundNotification } from 'store/App/Preferences';
import { sortUsersByRecentFirst, sortUsersByUnreadCount, updateLastActiveAt } from 'store/Message/messageList/actions';
import { messageListAPI } from 'store/Message/messageList/api';
import { processLastSentMessage } from 'store/Message/messageOverview/actions';
import { RootState } from 'store/rootReducer';

import {
	getFacetsSortFromLs,
	getLiteFacetsSortFromLs,
	getLiteFacetsSortFromLsMyConversation,
	getLiteQuickFiltersFromLS,
	getUserMessagesAction,
	markConversationAsReadOrUnread,
	setSelectedUserAction,
	setUserListAction,
	updateLastTranslatedMessageStateAction,
	updateMessagesWithTransaltedTextAction,
	updateSelectedUserInUserListAction,
} from '../../Message/actions';
import { actions } from '../../Message/store';
import { channelList, isMasterAdmin, isOwner, notificationActionTitles, SOCKET_EVENTS } from '../consts';
import { currentTab } from '../helper';

import { generalNotificationListener, notifyVideoStatusListener } from './general';
import {
	notifyGroupConversationExitListener,
	notifyGroupConversationInvitationSentListener,
	notifyGroupConversationInviteExpireListener,
	notifyGroupConversationInviteListener,
	notifyGroupConversationJoinListener,
	notifyGroupConversationPendingResolutionListener,
	notifyGroupConversationResolutionListener,
	notifyGroupVideoConversationInviteListener,
} from './groupConversation';
import {
	askUserIdListener,
	liveChatStatusChangeForAllBots,
	liveChatTransferListener,
	newLiveChatRequestAssignedListener,
	newLiveChatRequestEngagedListener,
	newLiveChatRequestListener,
} from './livechatEvents';
import {
	chatStatusChangeListener,
	notifyChatStateUpdateListener,
	notifyConversationReadStatusListener,
	notifyExitLiveChatListener,
	notifyNewMessageStatus,
	notifySenderActionListener,
	notifyTestBroadcastStatusListener,
} from './messageStatus';
import {
	notifyCommentRepliesSyncupStatusListener,
	notifyCommentsSyncupStatusListener,
	notifyNewCommentListener,
} from './socialEvents';

const { setMessageCount, setUserMessages, updateLastUserMessage, setSelectedUserData } = actions;

const clearTimeoutTypingOff: any = null;

const payload_identifier = '';

export let socket: any = null;

export const initSocket = (sock: any) => (dispatch: any) => {
	socket = sock;
	dispatch(liveChatStatusChangeForAllBots(socket, liveChatStatusChange));

	dispatch(newLiveChatRequestListener(socket, liveChatStatusChange));
	dispatch(askUserIdListener(socket, liveChatStatusChange));
	dispatch(newLiveChatRequestAssignedListener(socket, liveChatStatusChange));
	dispatch(notifySenderActionListener(socket, clearTimeoutTypingOff));
	dispatch(liveChatTransferListener(socket, liveChatStatusChange));
	dispatch(notifyNewMessagesListener());
	dispatch(chatStatusChangeListener(socket, getConversationData));
	dispatch(notifyExitLiveChatListener(socket));
	dispatch(switchToAgentListener());
	dispatch(notifyChatStateUpdateListener(socket, liveChatStatusChange, getConversationData));
	dispatch(newLiveChatRequestEngagedListener(socket, liveChatStatusChange));
	dispatch(generalNotificationListener(socket));
	dispatch(notifyVideoStatusListener(socket));
	dispatch(notifyConversationReadStatusListener(socket, payload_identifier));

	dispatch(notifyGroupConversationInviteListener(socket, liveChatStatusChange));
	dispatch(notifyGroupConversationJoinListener(socket));
	dispatch(notifyGroupConversationExitListener(socket));
	dispatch(notifyGroupConversationInviteExpireListener(socket));
	dispatch(notifyGroupConversationInvitationSentListener(socket));
	dispatch(notifyGroupConversationPendingResolutionListener(socket));
	dispatch(notifyGroupConversationResolutionListener(socket));
	dispatch(notifyGroupVideoConversationInviteListener(socket, liveChatStatusChange));

	dispatch(notifyCommentsSyncupStatusListener(socket));
	dispatch(notifyCommentRepliesSyncupStatusListener(socket));
	dispatch(notifyNewCommentListener(socket));

	dispatch(notifyTestBroadcastStatusListener(socket));
	dispatch(notifyNewMessageStatus(socket));
};

export const socketReconnectionListener = (sock: any) => (dispatch: any) => {
	if (!sock) {
		return;
	}
	sock.removeAllListeners(SOCKET_EVENTS.RECONNECT);
	sock.on(SOCKET_EVENTS.RECONNECT, (attempt: number) => {
		console.log('reconnecting....', attempt);
		dispatch(initSocket(sock));
	});
};

export const getConversationData = () => (dispatch: any, getState: any) => {
	const { selectedUser } = getState().Message;
	const { roleName } = getState().User.profile.data;
	const { uid } = getState().User.botAdmin.data;
	const agentCAUserId = getState().Message.agentId;
	const botRef = getState().Bot.data.bot_ref;

	if (Object.keys(selectedUser)?.length === 0) {
		return;
	}

	const params = {
		botRef,
		userId: selectedUser?.id,
		loggedInUserRole: roleName,
		conversationId: selectedUser.conversation_id,
		commonAdminUid: uid,
		actorId: agentCAUserId,
	};
	messageListAPI.getSelectedUserData(params).then((response: any) => {
		if (isValidResponseObject(response)) {
			dispatch(setSelectedUserData(response.data.responseObject));
		}
	});
};

export const liveChatStatusChange = (tab?: string) => (dispatch: any, getState: any) => {
	const botDetail = getState().Bot.data;
	const userDetail = getState().User.botAdmin.data;

	const liveChatEmitStatusObj: any = {
		uid: userDetail.uid,
		bot_key: botDetail.bot_key,
		category: botDetail.bot_category,
		is_supervisor_owner: isOwner(userDetail.admin_mode_access.role),
		bot_ref: botDetail.bot_ref,
	};
	if (tab === MESSAGE_TAB_HEADERS_MAP.unassigned) {
		liveChatEmitStatusObj['unassigned_user_id_list'] = true;
	}

	if (userDetail?.admin_mode_access?.role && botDetail) {
		socket && socket.emit(SOCKET_EVENTS.LIVECHAT_STATUS_CHANGE, liveChatEmitStatusObj);
	}
};

export const notifyNewMessagesListener = () => (dispatch: any, getState: () => RootState) => {
	socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_NEW_MESSAGES);

	socket.on(SOCKET_EVENTS.NOTIFY_NEW_MESSAGES, (d: any) => {
		const data = JSON.parse(d);
		const { selectedUser } = getState().Message;
		const { selectedVideoUser } = getState().Jitsi;
		const userDetail = getState().User.botAdmin.data;
		const botRefList = getState().BotList?.botRefList;
		const isSimplifiedOneViewEnabled = getState().User.botAdmin.data.is_simplified_one_view_enabled;
		const { newMessageNotification, soundOnMessage } = getState().Preferences.notifications;

		const leftPanelsV2Data: any = userDetail.left_panels_v2_keys || [];
		const soundOnMessageCheck = soundOnMessage === null ? !isSimplifiedOneViewEnabled : soundOnMessage;
		const newMessageNotificationCheck =
			newMessageNotification === null ? !isSimplifiedOneViewEnabled : newMessageNotification;

		const botRef = getState().Bot.data.bot_ref;

		const userId = Object.keys(data)[0];
		const { message } = data[userId];
		const conversationId = data[userId].conversation_id;
		const isNewBannerEnabled = getState().User.botAdmin.data?.enable_start_conversation_from_backend || false;

		const messageTypingLoader = document.getElementById('message-typing-loader');
		if (messageTypingLoader) {
			messageTypingLoader.style.display = 'none';
		}

		const sendMessageNotification = (userKey: any) => {
			if (
				data[userKey].sender !== 'bot' ||
				(data[userKey].participant_details && botRefList?.includes(data[userId].bot_ref))
			) {
				if (isOwner(userDetail.admin_mode_access.role) || leftPanelsV2Data.indexOf('conversation') > -1) {
					const { state } = data[userKey];
					let url = '';
					if (isSimplifiedOneViewEnabled && state !== MESSAGE_STATE_MAP.REQUESTED) {
						url = `/allmessages/${conversationId}`;
					} else if (state === MESSAGE_STATE_MAP.ENGAGING || state === MESSAGE_STATE_MAP.PENDING_RESOLUTION) {
						url = `/messages/active/${conversationId}`;
					} else if (state === MESSAGE_STATE_MAP.ASSIGNED) {
						url = `/messages/new/${conversationId}`;
					} else if (state === MESSAGE_STATE_MAP.REQUESTED) {
						url = `/messages/unassigned/${conversationId}`;
					}
					if (
						data[userKey].participant_details &&
						data[userKey].participant_details[userDetail.uid].state === GROUP_CONVERSATION_STATES.joined
					) {
						if (isSimplifiedOneViewEnabled) {
							url = `/allmessages/${conversationId}`;
						} else {
							url = `/messages/active/${conversationId}`;
						}
					}

					if (newMessageNotificationCheck) {
						soundOnMessageCheck && dispatch(playSoundNotification({ sound: 'message' }));
						dispatch(
							addNotificationAction({
								title: notificationActionTitles.newConversationRequest,
								description: message,
								url,
								type: SOCKET_EVENTS.NOTIFY_NEW_MESSAGES,
								timestamp: data[userId].timestamp,
								userId,
								userName: data[userId].username.replaceAll(/"/gi, ''),
								conversationId,
								botRef: data[userId].bot_ref,
							})
						);
					}
				}
			}
		};

		Object.keys(data).forEach(function (userKey) {
			if (data[userKey] && selectedUser && data[userKey].conversation_id && botRef === data[userId].bot_ref) {
				const {
					agent_email,
					conversation_id,
					emailData,
					message_id,
					messagetype,
					timestamp,
					sender,
					username,
					linked_event_id,
					linked_response_data,
				} = data[userKey];
				const tab = currentTab();

				const updatedUsers = updateLastActiveAt(getState().Message.userList.data, conversation_id, timestamp);

				if (conversation_id === selectedUser.conversation_id) {
					const userDetail = getState().User.botAdmin.data;
					const isAutoTranslateEntitlementEnabled = getState().Bot.configData.enable_auto_translation;
					const userMessagesData = getState().Message.userMessages.data;
					const {
						isTranslateIntegrationEnabled,
						translationStatusOfAgent,
						isAutoTranslateEnabledInLiveChat,
					} = getState().Message.autoTranslationConfigs;
					const agentPreferredLanguage =
						getState().User.profile.data.preferredLanguage || DEFAULT_LANGUAGE_CODE;
					if (!isMasterAdmin) {
						dispatch(conversationReadEmitter(conversation_id));
					}

					let process = true;
					if (agent_email && agent_email === userDetail.email) {
						process = false;
					}
					if (process) {
						const userMessages = Array.isArray(userMessagesData)
							? JSON.parse(JSON.stringify(userMessagesData))
							: [];
						const message: IObjProps = {
							messagetype: messagetype.toUpperCase(),
							timestamp,
							message: data[userKey].message,
							is_app_message: data[userKey].is_app_message,
							platform: selectedUser.platform,
							sender,
						};
						if (agent_email) {
							message.agent_email = agent_email;
							message.is_agent_reply = true;
						}
						if (messagetype === MESSAGE_TYPE_MAP.emailReceived) {
							message.emailData = emailData;
							message.username = username;
						}
						if (message_id) {
							message.messageId = message_id;
						}
						if (linked_event_id) {
							message.linkedEventId = linked_event_id;
							message.linkedResponseData = linked_response_data;
						}
						if (
							isAutoTranslateEntitlementEnabled &&
							isTranslateIntegrationEnabled &&
							translationStatusOfAgent &&
							isAutoTranslateEnabledInLiveChat &&
							messagetype === MESSAGE_TYPE_MAP.messageReceived
						) {
							dispatch(
								updateMessagesWithTransaltedTextAction(
									[message.message],
									agentPreferredLanguage,
									(translatedMessagesList: any) => {
										const translatedMessage: IObjProps = { ...message };
										translatedMessage['translatedMessage'] =
											translatedMessagesList[0]['translatedText'];
										translatedMessage['isTranslated'] = true;
										userMessages.push(translatedMessage);
										dispatch(
											updateLastTranslatedMessageStateAction(
												TRANSLATED_LAST_MESSAGE_STATE.TO_PROCESS
											)
										);
										dispatch(setUserMessages(userMessages));
									}
								)
							);
						} else {
							userMessages.push(message);
							dispatch(setUserMessages(userMessages));
						}
						if (
							!isEmpty(selectedVideoUser) &&
							selectedVideoUser.conversation_id === selectedUser.conversation_id
						) {
							dispatch(jitsiActions.setVideoUserMessages(userMessages.data));
						}
					} else if (channelList.includes(selectedUser.platform)) {
						const userMessages = Array.isArray(userMessagesData)
							? JSON.parse(JSON.stringify(userMessagesData))
							: [];
						if (!isEmpty(data[userKey].system_generated_msg_id)) {
							const updatedUserMessages = [...userMessages];
							for (let i = 0; i < updatedUserMessages.length; i++) {
								if (updatedUserMessages[i].messageId === data[userKey].system_generated_msg_id) {
									updatedUserMessages[i].messageId = data[userKey].message_id;
									break;
								}
							}
							dispatch(setUserMessages(updatedUserMessages));
						}
					}
					if (!document.hasFocus() && !isValidJson(message)) {
						sendMessageNotification(userKey);
					}
					dispatch(
						setSelectedUserAction({ ...getState().Message.selectedUser, last_active_at: timestamp }, false)
					);
				} else if (sender !== 'bot') {
					let messageCountMap: IObjProps = {};
					if (getState().Message.messageCountMap) {
						messageCountMap = {
							...getState().Message.messageCountMap,
						};
					}
					if (messageCountMap[data[userKey].conversation_id]) {
						messageCountMap[data[userKey].conversation_id]++;
					} else {
						messageCountMap[data[userKey].conversation_id] = 1;
					}
					!isValidJson(message) && sendMessageNotification(userKey);
					dispatch(setMessageCount(messageCountMap));
					if (
						tab === MESSAGE_TAB_HEADERS_MAP.new ||
						tab === MESSAGE_TAB_HEADERS_MAP.active ||
						tab === MESSAGE_TAB_HEADERS_MAP.unassigned
					) {
						const filters: any = dispatch(getFacetsSortFromLs(tab));
						if (filters && filters.sort === messageSort.unreadDesc) {
							const sortedUsers = sortUsersByUnreadCount(updatedUsers, messageCountMap);
							dispatch(setUserListAction(sortedUsers));
						}
					}
				}
				if (!isSimplifiedOneViewEnabled && tab) {
					const filters: any = dispatch(getFacetsSortFromLs(tab));
					if (filters?.sort === messageSort.recentFirst || filters?.sort === messageSort.recentUserFirst) {
						dispatch(setUserListAction(sortUsersByRecentFirst(updatedUsers, conversation_id, timestamp)));
					}
				}
				if (isSimplifiedOneViewEnabled) {
					const quickFilters: any = dispatch(getLiteQuickFiltersFromLS());

					const defaultFilters: any = quickFilters?.myConversations
						? dispatch(getLiteFacetsSortFromLsMyConversation())
						: dispatch(getLiteFacetsSortFromLs());
					const filters = {
						facets: defaultFilters.facets,
						sort: defaultFilters.sort,
					};
					if (
						messagetype !== MESSAGE_TYPE_MAP.agentParticipation &&
						(filters?.sort === SORTING.recentFirst || filters?.sort === SORTING.recentUserFirst)
					) {
						dispatch(setUserListAction(sortUsersByRecentFirst(updatedUsers, conversation_id, timestamp)));
					}
				}
				if (sender === 'bot') {
					dispatch(conversationReadEmitter(conversation_id, true));
				}
				dispatch(
					updateLastUserMessage({
						id: data[userKey].conversation_id,
						message: processLastSentMessage(data[userId]),
						lastActiveAt: getState().Message?.lastMessage[conversation_id]?.lastActiveAt,
					})
				);
			} else if (botRefList?.includes(data[userId].bot_ref)) {
				!isValidJson(message) && sendMessageNotification(userKey);
			}
		});
		if (isNewBannerEnabled) {
			if (selectedUser.is_enable && selectedUser.is_live_chat_enable) {
				return;
			}
			dispatch(getConversationData());
		}
	});
};

export const switchToAgentListener = () => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.SWITCH_TO_AGENT);

	socket.on(SOCKET_EVENTS.SWITCH_TO_AGENT, (d: any) => {
		const botAdminData = getState().User.botAdmin.data;
		const userListData = getState().Message.userList.data;
		const data = JSON.parse(d);
		let dispatchAction = false;
		const users = Array.isArray(userListData) ? JSON.parse(JSON.stringify(userListData)) : [];
		const { selectedUser } = getState().Message;
		const isNewBannerEnabled = getState().User.botAdmin.data?.enable_start_conversation_from_backend;
		const messagesLength = getState().Message.userMessages.data.length;
		const messageApiDelay = getState().Bot.data.fetch_message_api_delay;

		if (isNewBannerEnabled) {
			setTimeout(() => {
				dispatch(getConversationData());
			}, 2000);
		}
		const scrollToViewLatestMessage = () => {
			const newMessageIndicator: any = document.getElementsByClassName(
				`message-index-${
					getState().Message.userMessages.data[messagesLength - 1].messagetype === 'TEXT'
						? messagesLength - 1
						: messagesLength - 2
				}`
			);
			newMessageIndicator?.length &&
				newMessageIndicator[0]?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
		};

		const fetchUserMessage = (user: any) => {
			setTimeout(() => {
				dispatch(setSelectedUserAction(user));
				dispatch(
					getUserMessagesAction(
						{
							usersData: selectedUser,
							isUserMessagesDataAppended: true,
							isUserMessagesDataPrepended: true,
							fetchOnlyLastMessage: false,
							isSolrQuery: true,
							isActiveTab: true,
						},
						scrollToViewLatestMessage
					)
				);
			}, messageApiDelay);
		};

		Object.keys(data).forEach(function (userKey) {
			for (let i = 0; i < users.length; i++) {
				if (users[i].conversation_id === data[userKey].conversation_id) {
					users[i].is_enable = false;
					if (!users[i].actor_id) {
						users[i].actor_id = botAdminData.uid;
					}
					if (selectedUser.conversation_id === users[i].conversation_id) {
						dispatchAction = true;
						fetchUserMessage(users[i]);
					}
					break;
				}
			}

			!dispatchAction &&
				setTimeout(() => {
					dispatch(
						getUserMessagesAction(
							{
								usersData: selectedUser,
								isUserMessagesDataAppended: true,
								isUserMessagesDataPrepended: true,
								fetchOnlyLastMessage: false,
								isSolrQuery: true,
								isActiveTab: true,
							},
							scrollToViewLatestMessage
						)
					);
				}, messageApiDelay);
		});
		const updatedSelectedUser = { ...selectedUser };
		updatedSelectedUser.is_enable = false;
		updatedSelectedUser.is_live_chat_enable = true;

		dispatch(updateSelectedUserInUserListAction(updatedSelectedUser));
	});
};

export const typingIndicatorStatusEmit = (text: string) => (dispatch: any, getState: () => RootState) => {
	const botAdminData: any = getState().User.botAdmin.data;
	const { selectedUser } = getState().Message;
	const { actor_email, bot_ref, conversation_id, platform, user_id } = selectedUser;

	const socketMessage: any = {
		userIds: [user_id],
		bot_id: bot_ref,
		platform,
		agent_msg_details: {
			agent_email: actor_email,
			event_timestamp: new Date().toISOString(),
			agent_name: botAdminData?.name || '',
			agent_avatar:
				botAdminData.profileImg ||
				initialsCanvasData({
					username: botAdminData.name,
					id: botAdminData.uid,
				}),
		},
		data: {
			status_type: 'SENDER_ACTION',
			action: 'typing_on',
			type: 'STATUS',
		},
	};
	socketMessage['conversation_id_map'] = {};
	socketMessage['conversation_id_map'][user_id] = conversation_id;
	text && socket && socket.emit('respond', socketMessage);
};

export const conversationReadEmitter =
	(conversationId: string, isSenderBot = false) =>
	(dispatch: any, getState: () => RootState) => {
		const botRef = getState().Bot.data.bot_ref;
		const { uid } = getState().User.botAdmin.data;
		const { selectedUser } = getState().Message;

		const { conversation_id } = selectedUser;

		if (selectedUser.actor_id !== uid) {
			return;
		}

		if (botRef && conversation_id) {
			socket.emit(SOCKET_EVENTS.CONVERSATION_READ, {
				agent_id: uid,
				bot_ref: botRef,
				conversation_id: isSenderBot ? conversationId : conversation_id,
			});
		}
		dispatch(markConversationAsReadOrUnread(isSenderBot ? conversationId : conversation_id, true));
	};

export const starredConversationEmitter =
	(conversationId: string, isStarred: boolean) => (dispatch: any, getState: () => RootState) => {
		const { uid } = getState().User.botAdmin.data;
		const botRef = getState().Message.selectedUser.bot_ref;
		if (botRef && conversationId) {
			socket.emit(SOCKET_EVENTS.STAR_CONVERSATION, {
				conversation_id: conversationId,
				uid,
				bot_ref: botRef,
				star_conversation: isStarred,
			});
		}
	};
