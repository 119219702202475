import { i18nHelper as t } from 'i18nHelper';

import { IObjProps } from './consts/types';
import { ButtonType } from './enum/templatesEnum';

export const getAttributeOption = (attrname: string, flowAttributes: any) => {
	let id = '';
	flowAttributes?.forEach((attribute: any) => {
		if (attribute.name === attrname) {
			id = attribute.id;
		}
	});

	return [{ id, label: attrname }];
};

export const getCategoryName = (id: string, availableFlows: any) => {
	let label = '';
	if (availableFlows) {
		for (let i = 0; i < availableFlows.length; i++) {
			if (availableFlows[i].id === id) {
				label = availableFlows[i].label;
				break;
			}
		}
	}

	return [{ id, label }];
};

export const updateButtonAttributes = (
	payload: string,
	buttonIndex: any,
	setInitValue: any,
	flowAttributes: any,
	cardIndexId: string
) => {
	const payloadAttributes = payload.split('&');
	payloadAttributes.forEach((attribute: any, index: any) => {
		setInitValue(
			`${cardIndexId}buttons[${buttonIndex}].attributes[${index}].name`,
			getAttributeOption(attribute.split('=')[0], flowAttributes)
		);
		setInitValue(`${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`, attribute.split('=')[1]);
	});
};

export const getNodeLabel = (id: string, templates: any) => {
	let label = '';
	let components: any;
	let namespace: any;
	let language: any;
	if (templates) {
		for (let i = 0; i < templates.length; i++) {
			if (templates[i].id === id) {
				label = templates[i].label;
				components = templates[i].components;
				namespace = templates[i].namespace;
				language = templates[i].language;
				break;
			}
		}
	}

	return [{ id, label, components, namespace, language }];
};

export enum componentFormat {
	text = 'TEXT',
	image = 'IMAGE',
	documentType = 'DOCUMENT',
	video = 'VIDEO',
}

export enum componentType {
	header = 'HEADER',
	body = 'BODY',
	buttons = 'BUTTONS',
	footer = 'FOOTER',
	carousel = 'CAROUSEL',
	lto = 'LIMITED_TIME_OFFER',
}

export enum templateComponentParameters {
	image = 'image',
	documentType = 'document',
	video = 'video',
	text = 'text',
	imageLink = 'fileUrl',
	docLink = 'fileUrlDoc',
	fileName = 'mediaDoc',
	file = 'file',
	body = 'body',
	parametersBody = 'parameters[body]',
	button = 'button',
	buttons = 'buttons',
	header = 'header',
	carousel = 'carousel',
	lto = 'limited_time_offer',
	expiration = 'expiration',
	url = 'url',
}

export enum TEMPLATE_BUTTON_TYPES {
	URL = 'url',
	QUICK_REPLY = 'quick_reply',
}

export enum CAROUSEL_HEADER_TYPES {
	IMAGE = 'Image',
	VIDEO = 'Video',
}
export const waitTimeForStatusPacket = () => [
	{
		id: '2',
		label: t('components:node.templateMesaage.minutes', {
			minutes: 2,
		}),
	},
	{
		id: '5',
		label: t('components:node.templateMesaage.minutes', {
			minutes: 5,
		}),
	},
	{
		id: '10',
		label: t('components:node.templateMesaage.minutes', {
			minutes: 10,
		}),
	},
	{
		id: '20',
		label: t('components:node.templateMesaage.minutes', {
			minutes: 20,
		}),
	},
];

export const typeOfStatusPacket = () => [
	{
		id: 'sent',
		label: t('components:node.templateMesaage.sent'),
	},
	{
		id: 'delivered',
		label: t('components:node.templateMesaage.delivered'),
	},
	{
		id: 'failed',
		label: t('components:node.templateMesaage.failed'),
	},
	{
		id: 'read',
		label: t('components:node.templateMesaage.read'),
	},
	{
		id: 'NA',
		label: t('components:node.templateMesaage.noStatusPacketReceived'),
	},
];

export const getComponentByType = (components: any, type: string) =>
	components?.filter((component: any) => component.type === type)[0];

export const replacePlaceholders = (text: string, parameters: any[]) => {
	parameters.map((param: any, index: number) => {
		if (param) {
			text = text.replaceAll(`{{${index + 1}}}`, param?.text);
		}
	});

	return text;
};

export const getCarouselPayload = (data: any) => {
	const { components, template } = data || {};
	const cardComponents: Array<any> = [];
	const bodyData = getComponentByType(components, templateComponentParameters.body);
	const cards: any = getComponentByType(components, templateComponentParameters.carousel)?.cards;
	let bodyText = getComponentByType(template?.components, componentType.body)?.text;

	if (bodyData) {
		bodyText = replacePlaceholders(bodyText, bodyData.parameters);
	}
	if (cards) {
		const templateCardsData = getComponentByType(template?.components, componentType.carousel)?.cards;
		cards?.forEach((card: any, index: number) => {
			if (templateCardsData?.[index]) {
				let header: IObjProps = {};
				let cardBodyString = getComponentByType(templateCardsData[index]?.components, componentType.body)?.text;
				const buttonsArray: Array<any> = [];

				const cardHeaderComponent = getComponentByType(card?.components, templateComponentParameters.header);
				const cardBodyComponent = getComponentByType(card?.components, templateComponentParameters.body);
				const cardButtonsData = getComponentByType(
					templateCardsData[index]?.components,
					componentType.buttons
				)?.buttons;

				if (cardHeaderComponent?.parameters[0].image?.link) {
					header = {
						headerFormat: CAROUSEL_HEADER_TYPES.IMAGE,
						headerInputValue: cardHeaderComponent.parameters[0].image.link,
					};
				} else if (cardHeaderComponent?.parameters[0].video?.link) {
					header = {
						headerFormat: CAROUSEL_HEADER_TYPES.VIDEO,
						headerInputValue: cardHeaderComponent.parameters[0].video.link,
					};
				}
				if (cardBodyComponent) {
					cardBodyString = replacePlaceholders(cardBodyString, cardBodyComponent.parameters);
				}
				if (cardButtonsData) {
					cardButtonsData.forEach((button: any, buttonIndex: any) => {
						buttonsArray.push({ label: cardButtonsData[buttonIndex].text });
					});
				}

				cardComponents.push({
					header,
					body: {
						bodyText: cardBodyString,
					},
					buttons: buttonsArray,
				});
			}
		});
	}

	return { cardComponents, bodyText, language: template?.language };
};

export const isAuthenticationTemplate = (currentTemplate: IObjProps) =>
	currentTemplate?.components?.some?.(
		(component: IObjProps) =>
			component.type === componentType.buttons &&
			component.buttons?.some?.(
				(button: IObjProps) => button.type === ButtonType.OTP && button.otp_type === ButtonType.COPY_CODE
			)
	);
