// RUD
export const PATH_BUILDER_RUD_ENTITY_USER_PROMPT = 'path-builder-rud-entity-user-prompt';
export const PATH_BUILDER_RUD_ENTITY_NAME = 'path-builder-rud-entity-name';
export const PATH_BUILDER_RUD_STANDARD = 'path-builder-rud-standard';
export const PATH_BUILDER_RUD_ENTITY = 'path-builder-rud-entity';
export const PATH_BUILDER_RUD_USER_PROMPT = 'path-builder-rud-user-prompt';
export const PATH_BUILDER_RUD_VALIDATION_TYPE = 'path-builder-rud-validation-type';
export const PATH_BUILDER_RUD_ENABLE_REGEX = 'path-builder-rud-enable-regex';
export const PATH_BUILDER_RUD_REGEX_VALUE = 'path-builder-rud-regex-value';
export const PATH_BUILDER_RUD_VALIDATION_MESSAGE = 'path-builder-rud-validation-message';
export const PATH_BUILDER_RUD_ATTRIBUTE_NAME = 'path-builder-rud-attribute-name';
export const PATH_BUILDER_RUD_DATE_PICKER = 'path-builder-rud-date-picker';
export const PATH_BUILDER_RUD_DEFAULT_TIMEZONE = 'path-builder-rud-default-timezone';
export const PATH_BUILDER_RUD_OVERRIDE_TIMEZONE = 'path-builder-rud-override-timezone';
export const PATH_BUILDER_RUD_REGEX = 'path-builder-rud-regex';
export const PATH_BUILDER_RUD_UPDATE_USERNAME = 'path-builder-rud-update-username';
export const PATH_BUILDER_RUD_CREATE_ENTITY = 'path-builder-rud-create_entity';
export const PATH_BUILDER_RUD_ENABLE_SKIP = 'path-builder-rud-enable-skip';
export const PATH_BUILDER_RUD_SKIP_TEXT = 'path-builder-rud-skip-text';
export const PATH_BUILDER_RUD_SKIP_TRIGGER_NODE = 'path-builder-rud-skip-trigger-node';

export const PATH_BUILDER_RECT_START = 'path-builder-rect-start';
export const PATH_BUILDER_UPDATE_PATH_NAME = 'path-builder-update-path-name';
export const PATH_BUILDER_PATH_INFO = 'path-builder-path-info';
export const PATH_BUILDER_MARK_PATH_TO_AGENT = 'path-builder-mark-path-to-agent';
export const PATH_BUILDER_TEST_PATH = 'path-builder-test-path';
export const PATH_BUILDER_ADD_NODE = 'path-builder-add-node';

// NODE
export const NODE = 'node';

// SM
export const PATH_BUILDER_SM_MESSAGE = 'path-builder-sm-message';

// SMO
export const PATH_BUILDER_SMO_MESSAGE = 'path-builder-smo-message';
export const PATH_BUILDER_SMO_ACTION_TRIGGER_PATH = 'path-guilder-smo-action-trigger-path';
export const PATH_BUILDER_SMO_ACTION_SET_ATTRIBUTE = 'path-builder-smo-action-set-attribute';
export const PATH_BUILDER_SMO_ATTRIBUTE_NAME = 'path-builder-smo-attribute-name';
export const PATH_BUILDER_SMO_ALLOW_MULTI_SELECT = 'path-builder-smo-allow-multi-select';
export const PATH_BUILDER_SMO_USER_PROMPT = 'path-builder-smo-user-prompt';
export const PATH_BUILDER_SMO_DONE_BUTTON_TITLE = 'path-builder-smo-done-button-title';
export const PATH_BUILDER_SMO_DISPLAY_MODE_BUTTONS = 'path-builder-smo-display-mode-buttons';
export const PATH_BUILDER_SMO_DISPLAY_MODE_DROPDOWN_MENU = 'path-builder-smo-display-mode-dropdown-menu';
export const PATH_BUILDER_SMO_DISPLAY_MODE_NUMERICAL_LIST = 'path-builder-smo-display-mode-numerical-list';
export const PATH_BUILDER_SMO_DISPLAY_MODE_INTERACTIVE_BUTTONS = 'path-builder-smo-display-mode-interactive-buttons';
export const PATH_BUILDER_SMO_VALIDATION_MODE_DEFAULT = 'path-builder-smo-validation-mode-default';
export const PATH_BUILDER_SMO_VALIDATION_MODE_VALIDATION_ONCE = 'path-builder-smo-validation-mode-validation-once';
export const PATH_BUILDER_SMO_VALIDATION_MODE_FORCE_VALIDATION = 'path-builder-smo-validation-mode-force-validation';
export const PATH_BUILDER_SMO_VALIDATION_MESSAGE = 'path-builder-smo-validation-message';
export const PATH_BUILDER_SMO_DOWNLOAD_OPTIONS = 'path-builder-smo-download-options';
export const PATH_BUILDER_SMO_BUTTON_LIST = 'path-builder-smo-button-list';
export const PATH_BUILDER_SMO_BUTTON_IMPORT = 'path-builder-smo-button-import';
export const PATH_BUILDER_SMO_OPTION_NAME = 'path-builder-smo-option-name';
export const PATH_BUILDER_SMO_OPTION_VALUE = 'path-builder-smo-option-value';
export const PATH_BUILDER_SMO_OPTION_TITLE = 'path-builder-smo-option-title';
export const PATH_BUILDER_SMO_SELECT_CONNECTION = 'path-builder-smo-select-connection';
export const PATH_BUILDER_SMO_SOURCE = 'path-builder-smo-source';
export const PATH_BUILDER_SMO_ERROR_MSG = 'path-builder-smo-error-msg';
export const PATH_BUILDER_SMO_DYNAMIC_ATTR = 'path-builder-smo-dynamic-attr';

// SA
export const PATH_BUILDER_SA_NAME = 'path-builder-sa-name';
export const PATH_BUILDER_SA_TYPE = 'path-builder-sa-type';
export const PATH_BUILDER_SA_VALUE = 'path-builder-sa-value';
export const ENABLE_EVENT = 'enable-event';
export const EVENT_NAME = 'event-name';
export const ADD_NEW_EVENT = 'add-new-event';
export const RESET_TO_NULL = 'reset_to_null';

// SEND IMAGE
export const PATH_BUILDER_SI_IMAGE = 'path-builder-si-image';
export const PATH_BUILDER_SI_ALTERNATE_TEXT = 'path-builder-si-alternate-text';
export const PATH_BUILDER_SI_DEFAULT_ERROR = 'path-builder-si-default-error';

// SEND VIDEO
export const PATH_BUILDER_SV_VIDEO = 'path-builder-sv-video';
export const PATH_BUILDER_SV_CAPTION = 'path-builder-sv-caption';
export const PATH_BUILDER_SV_DEFAULT_ERROR = 'path-builder-sv-default-error';

// SEND AUDIO
export const PATH_BUILDER_SA_AUDIO = 'path-builder-sa-audio';
export const PATH_BUILDER_SA_CAPTION = 'path-builder-sa-caption';
export const PATH_BUILDER_SA_DEFAULT_ERROR = 'path-builder-sa-default-error';

// CAROUSEL
export const CAROUSEL = {
	SOURCE_TYPE: 'path-builder-carousel-source-type',
	IMAGE_URL: 'path-builder-carousel-image-url',
	ATTRIBUTE_NAME: 'path-builder-carousel-attribute-name',
	ERROR_MESSAGE: 'path-builder-carousel-error-message',
	EDIT_IMAGE: 'path-builder-carousel-edit-image',
	ADD_IMAGE: 'path-builder-carousel-add-image',
	ADD_BUTTON: 'path-builder-carousel-add-button',
	ADD_DROPDOWN: 'path-builder-carousel-add-dropdown',
	ADD_CARD: 'path-builder-carousel-add-card',
	STYLE_CONFIG_TYPE: 'path-builder-carousel-style-config-type',
	TITLE: 'path-builder-carousel-title',
	POST_BACK: 'post-back',
	WEB_URL: 'web-url',
	PHONE_NUMBER: 'phone-number',
	POST_BACK_URL: 'path-builder-carousel-post-back-url',
	PHONE: 'path-builder-carousel-phone',
	NODE_NAME: 'path-builder-carousel-node-name',
	ALT_TEXT: 'path-builder-carousel-alt-text',
	DEFAULT_ACTION_URL: 'path-builder-carousel-default-action-url',
	TOP_COLOR: 'path-builder-carousel-gradient-top-color',
	BOTTOM_COLOR: 'path-builder-carousel-gradient-bottom-color',
	PAYLOAD: 'path-builder-carousel-payload',
};

// CUSTOMCARD
export const CUSTOMCARD = {
	HTML_CONTENT: 'path-builder-custom-card-html-content',
	CSS_CONTENT: 'path-builder-custom-card-css-content',
	VERIFY: 'path-builder-custom-card-verify',
	ACCEPT: 'path-builder-custom-card-accept',
};

// DECISION
export const DECISION = {
	LEFT_OPERAND: 'path-builder-decision-left-operand',
	OPERATOR: 'path-builder-decision-operator',
	RIGHT_OPERAND: 'path-builder-decision-right-operand',
	EXPRESSION: 'path-builder-decision-expression',
	TRUE_PATH: 'path-builder-decision-true-path',
	FALSE_PATH: 'path-builder-decision-false-path',
};

// FEEDBACK
export const FEEDBACK = {
	STAR: 'path-builder-feedback-star',
	EMOJI: 'path-builder-feedback-emoji',
	FIVE: 'path-builder-feedback-rating-scale-5',
	TEN: 'path-builder-feedback-rating-scale-10',
	EMOJI_FIVE: 'path-builder-feedback-rating-scale-emoji-5',
	EMOJI_THREE: 'path-builder-feedback-rating-scale-emoji-3',
	PROMPT: 'path-builder-feedback-prompt',
	POST_MESSAGE: 'path-builder-feedback-post-message',
	CONVERSATION_ANALYTICS: 'path-builder-feedback-conversation-analytics',
	ENABLE_SKIP: 'path-builder-feedback-enable-skip',
};

// FILTERFAQ
export const FILTERFAQ = {
	ADD_CATEGORY: 'path-builder-filter-faq-add-category',
};

// PAUSE
export const PAUSE = {
	INPUT: 'path-builder-pause-input',
};

// PYTHONSCRIPT
export const PYTHONSCRIPT = {
	CODE: 'path-builder-python-script-code',
	ERROR_MESSAGE: 'path-builder-python-script-error-message',
	INITIALIZE_VARIABLES: 'path-builder-python-script-initialize-variables',
	VALIDATE: 'path-builder-python-script-validate',
};

// RANDOMIZE
export const RANDOMIZE = {
	TEXT: 'path-builder-randomize-text',
};

// SCRIPT
export const SCRIPT = {
	CODE: 'path-builder-script-code',
	ERROR_MESSAGE: 'path-builder-script-error-message',
	INITIALIZE_VARIABLES: 'path-builder-script-initialize-variables',
	VALIDATE: 'path-builder-script-validate',
};

// SELECTLANGUAGE
export const SELECTLANGUAGE = {
	INPUT: 'path-builder-select-language-input',
};

// SENDDOCUMENT
export const SEND_DOCUMENT = {
	URL: 'path-builder-send-document-url',
	ALT_TEXT: 'path-builder-send-document-alt-text',
	FILE_SIZE: 'path-builder-send-document-file-size',
	FILE_NAME: 'path-builder-send-document-file-name',
	ERROR_MESSAGE: 'path-builder-send-document-error-message',
	BROWSE: 'path-builder-send-document-browse',
};

// SENDEMAIL
export const SEND_EMAIL = {
	RESPONSE_TYPE: 'path-builder-send-email-response-type',
	SENDER_NAME: 'path-builder-send-email-sender-name',
	SENDER_EMAIL: 'path-builder-send-email-sender-email',
	RECIPIENT: 'path-builder-send-email-recipient',
	SUBJECT: 'path-builder-send-email-subject',
	BODY: 'path-builder-send-email-body',
	TEXT: 'path-builder-send-email-text',
};

// SHOPIFY
export const SHOPIFY = {
	ACTION: 'path-builder-shopify-action',
	STOP_FLOW: 'path-builder-shopify-stop-flow',
	CONTINUE_FLOW: 'path-builder-shopify-continue-flow',
	NOT_SEND_MESSAGE: 'path-builder-shopify-not-send-message',
	SEND_MESSAGE: 'path-builder-shopify-send-message',
	SEND_SMO: 'path-builder-shopify-send-smo',
	ERROR_MESSAGE: 'path-builder-shopify-error-message',
	CONTINUE_ERROR_MESSAGE: 'path-builder-shopify-continue-error-message',
	OPTION_TITLE: 'path-builder-shopify-option-title',
	OPTION_CONNECTION: 'path-builder-shopify-option-connection',
};

// WHATSAPP_COMMERCE
export const WHATSAPP_COMMERCE = {
	ACTION: 'path-builder-whatsapp-commerce-action',
	STOP_FLOW: 'path-builder-whatsapp-commerce-stop-flow',
	CONTINUE_FLOW: 'path-builder-whatsapp-commerce-continue-flow',
	NOT_SEND_MESSAGE: 'path-builder-whatsapp-commerce-not-send-message',
	SEND_MESSAGE: 'path-builder-whatsapp-commerce-send-message',
	SEND_SMO: 'path-builder-whatsapp-commerce-send-smo',
	ERROR_MESSAGE: 'path-builder-whatsapp-commerce-error-message',
	CONTINUE_ERROR_MESSAGE: 'path-builder-whatsapp-commerce-continue-error-message',
	OPTION_TITLE: 'path-builder-whatsapp-commerce-option-title',
	OPTION_CONNECTION: 'path-builder-whatsapp-commerce-option-connection',
};

// SUBSCRIBECAMPAIGN
export const SUBSCRIBE_CAMPAIGN = {
	SELECT: 'path-builder-subscribe-campaign-select',
};

// UNSUBSCRIBECAMPAIGN
export const UNSUBSCRIBE_CAMPAIGN = {
	SELECT: 'path-builder-unsubscribe-campaign-select',
};

// TRIGGERPATH
export const TRIGGER_PATH = {
	FLOW_KEY: 'path-builder-trigger-path-flow-key',
	ADD: 'path-builder-trigger-path-add',
	CREATE_PATH: 'create-path',
};

// TRANSFERAGENT
export const TRANSFER_AGENT = {
	ADD_CATEGORY: 'add-category',
	CATEGORY: 'path-builder-transfer-agent-category',
	TRIGGER_LIVE_CHAT: 'path-builder-transfer-agent-trigger-live-chat',
	ADD: 'path-builder-transfer-agent-add',
};

// ZAPIER
export const ZAPIER = {
	API_KEY: 'path-builder-zapier-api-key',
	GENERATE_KEY: 'path-builder-zapier-generate-key',
	CUSTOMER_ID: 'path-builder-zapier-customer-id',
	BOT_KEY: 'path-builder-zapier-bot-key',
	EVENT_KEY: 'path-builder-zapier-event-key',
};

// JSONAPI
export const JSON_API = {
	AUTHENTICATION_PATH_KEY: 'path-builder-json-api-authentication-path-key',
	ADD: 'path-builder-json-api-add',
	USERNAME: 'path-builder-json-api-user-name',
	PASSWORD: 'path-builder-json-api-password',
	AUTHENTICATION_TYPE: 'path-builder-json-api-authentication-type',
	BODY_TYPE: 'path-builder-json-api-body-type',
	BODY: 'BODY',
	STOP_FLOW: 'path-builder-json-api-stop-flow',
	CONTINUE_FLOW: 'path-builder-json-api-continue-flow',
	ERROR_MESSAGE: 'error-message',
	ADD_HEADERS: 'add-headers',
	ADD_PARAMS: 'add-params',
	EDIT: 'edit',
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
	C2EBRIDGE: 'C2EBridge',
	REQUEST_URL: 'REQUEST_URL',
	SEND_REQUEST: 'SEND_REQUEST',
};

// FILEUPLOAD
export const FILE_UPLOAD = {
	MESSAGE: 'path-builder-file-upload-message',
	PUBLIC_UPLOAD: 'path-builder-file-upload-public',
	DIRECT_UPLOAD: 'path-builder-file-upload-direct',
	POST: 'path-builder-file-upload-post',
	PUT: 'path-builder-file-upload-put',
	REQUEST_URL: 'path-builder-file-upload-request-url',
	ADD_PARAMS: 'path-builder-file-upload-add-params',
	ADD_HEADERS: 'path-builder-file-upload-add-headers',
	ADD_BODY: 'path-builder-file-upload-add-body',
	WHITE_LISTED_DOMAINS: 'path-builder-file-upload-white-listed-domains',
	ERROR_MESSAGE: 'path-builder-file-upload-error-message',
	ENABLE_SKIP: 'path-builder-file-upload-enable-skip',
};

// TEMPLATEMESSAGE
export const TEMPLATE_MESSAGE = {
	TEST_TEMPLATE: 'path-builder-test-template',
	TEMPLATE_COMPONENT: 'path-builder-template-component',
	TEMPLATE_NAME_SPACE: 'path-builder-template-name-space',
	TEMPLATE_LANGUAGE: 'path-builder-template-language',
	PHONE: 'path-builder-template-phone',
	OFFER_CODE: 'lto-offer-code',
	BROWSE: 'path-builder-template-browse',
	FILE_URL: 'path-builder-template-file-url',
	FILE_URL_DOC: 'path-builder-template-file-url-doc',
	PATH: 'path-builder-template-path',
	PATH_ATTRIBUTES: 'path-builder-template-path-attributes',
	SELECT_LANGUAGE: 'path-builder-template-select-language',
	TEMPLATE_NAME: 'path-builder-template-name',
	VIDEO: 'path-builder-template-video',
	IS_PHONE_NUMBER: 'path-builder-template-is-phone-number',
	AUTHENTICATION: 'path-builder-template-authentication',
};

// SLIDER
export const SLIDER = {
	INPUT_TYPE: 'path-builder-slider-input-type',
	FIVE: 'path-builder-slider-rating-scale-5',
	TEN: 'path-builder-slider-rating-scale-10',
	USER_PROMPT: 'path-builder-slider-user-prompt',
	VALIDATION_MESSAGE: 'path-builder-slider-validation-message',
	MIN_VALUE_INPUT: 'path-builder-slider-min-value-input',
	MAX_VALUE_INPUT: 'path-builder-slider-max-value-input',
	MIN_VALUE_NAME: 'path-builder-slider-min-value-name',
	MAX_VALUE_NAME: 'path-builder-slider-max-value-name',
	ZERO_INDEX: 'path-builder-slider-zero-index',
	STEP_VALUE: 'path-builder-slider-step-value',
	ATTRIBUTE_VALUE: 'path-builder-slider-attribute-value',
	ENABLE_SKIP: 'path-builder-slider-enable-skip',
};

// IDENTITY
export const IDENTITY = {
	TITLE: 'path-builder-identity-title',
	SUB_TITLE: 'path-builder-identity-sub-title',
	IDENTIFICATION_TYPE: 'path-builder-identification-type',
	SKIP_LOGIN: 'path-builder-identity-skip-login',
	DISPLAY_NAME: 'path-builder-identity-display-name',
	ATTRIBUTE_NAME: 'path-builder-identity-attribute-name',
	ATTRIBUTE_TYPE: 'path-builder-identity-attribute-type',
	UNIQUE_IDENTIFICATION: 'path-builder-identity-unique-identification',
};

// FORM
export const FORM = {
	TITLE: 'path-builder-form-title',
	SUB_TITLE: 'path-builder-form-sub-title',
	CONFIRMATION_TEXT: 'path-builder-form-confirmation-text',
	CONFIRMATION_MESSAGE: 'path-builder-form-confirmation-message',
	AFFIRMATIVE_TEXT: 'path-builder-form-affirmative-text',
	NEGATIVE_TEXT: 'path-builder-form-negative-text',
	SKIP_LOGIN: 'path-builder-form-skip-login',
	ENABLE_CONFIRMATION: 'path-builder-form-enable-confirmation',
	SKIP_TEXT: 'path-builder-form-skip-text',
	SKIP_MESSAGE: 'path-builder-form-skip-message',
	SKIP_AFFIRMATIVE_TEXT: 'path-builder-form-skip-affirmative-text',
	SKIP_NEGATIVE_TEXT: 'path-builder-form-skip-negative-text',
	OPTIONS_TEXT: 'path-builder-form-options-text',
	OPTIONS_POST_BACK: 'path-builder-form-options-post-back',
};

// WEBVIEW
export const WEBVIEW = {
	PROVIDER: 'path-builder-webview-provider',
	WEB_VIEW_TYPE: 'path-builder-webview-type',
	OAUTH_TYPE: 'path-builder-webview-oauth-type',
	URL_PREFIX_MESSAGE: 'path-builder-webview-url-prefix-message',
	REDIRECT_URL: 'path-builder-webview-redirect-url',
	REQUEST_URL: 'path-builder-webview-request-url',
	RESPONSE_PARAM: 'path-builder-webview-response-param',
	JSON_PARAM: 'path-builder-webview-json-param',
	SAMPLE_RESPONSE: 'path-builder-webview-sample-response',
	ADD: 'ADD',
	VALIDATE: 'VALIDATE',
	CURRENCY: 'path-builder-webview-currency',
	CONVERSATION_REQUIRED: 'path-builder-webview-conversation-required',
	BILLING_ADDRESS_COLLECTION: 'path-builder-webview-billing-address-collection',
	IS_TEST_MODE: 'path-builder-webview-is-test-mode',
};

// INTEGRATIONS
export const INTEGRATIONS = {
	KEY: 'path-builder-integration-key',
	ACCOUNT_ID: 'path-builder-integrations-account-id',
	WORK_FLOW_KEY: 'path-builder-work-flow-key',
};

export const RECURRING_NOTIFICATION_NODE = {
	TOPIC: 'path-builder-recurring-notification-topic',
	IMAGE: 'path-builder-recurring-notification-image',
	DAILY_FREQ: 'path-builder-recurring-notification-daily',
	WEEKLY_FREQ: 'path-builder-recurring-notification-weekly',
	MONTHLY_FREQ: 'path-builder-recurring-notification-monthly',
};
