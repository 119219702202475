import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';
import { REACT_APP_API_GATEWAY_BASE_URL } from 'shared/config/config';

const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const getWhatsappProviders = '/api/v1/whatsapp/providers';
const lineBot = '/api/v1/conf/line/';
const msTeams = '/api/v1/conf/msteams/';
const saveWhatsappBot = '/api/v1/conf/whatsapp/';
const skypeBot = '/api/v1/conf/skype/';
const skypeBotBusiness = '/api/v1/conf/skypeforbusiness/';
const slackBot = '/api/v1/conf/slack/';
const telegramBot = '/api/v1/conf/telegram/';
const twitterBot = '/api/v1/conf/twitter/';
const weChatBot = '/api/v1/conf/wechat/';
const whatsappConfiguredBots = '/api/v1/conf/whatsapp_configured_bots/';
const updateWhatsappBusinessProfileDetails = '/v2/whatsapp/profile/';
const disableChannel = '/api/v1/conf/disable/';

const genericProxyRequestAPI = new BaseService(genericProxyRequestUrl);
const getWhatsappProvidersAPI = new BaseService(getWhatsappProviders);
const lineBotAPI = new BaseService(lineBot);
const msTeamsAPI = new BaseService(msTeams);
const saveWhatsappBotAPI = new BaseService(saveWhatsappBot);
const skypeBotAPI = new BaseService(skypeBot);
const skypeBotBusinessAPI = new BaseService(skypeBotBusiness);
const slackBotAPI = new BaseService(slackBot);
const telegramBotAPI = new BaseService(telegramBot);
const twitterBotAPI = new BaseService(twitterBot);
const weChatBotAPI = new BaseService(weChatBot);
const gbmChatBotAPI = new BaseService('/api/v1/conf/gbm');
const whatsappConfiguredBotsAPI = new BaseService(whatsappConfiguredBots);
const whatsappProfileDetailsAPI = new BaseService(updateWhatsappBusinessProfileDetails);
const disableChannelAPI = new BaseService(disableChannel);
const apiGetWayGeneralProxyApi = new BaseService(REACT_APP_API_GATEWAY_BASE_URL);

export const API = {
	createLineBot(access_token) {
		const formData = new FormData();
		formData.append('line_access_token', access_token);

		return lineBotAPI.postRequest(formData);
	},
	createTelegramBot(access_token) {
		const formData = new FormData();
		formData.append('telegram_api_key', access_token);

		return telegramBotAPI.postRequest(formData);
	},
	createTwitterBot(
		twitterUserName,
		twitterConsumerApiKey,
		twitterConsumerApiSecretKey,
		twitterAccessToken,
		twitterAccessTokenSecret,
		twitterDevEnvLabel,
		twitterAccountActivityApiTier
	) {
		const formData = new FormData();
		formData.append('twitter_user_name', twitterUserName);
		formData.append('twitter_consumer_api_key', twitterConsumerApiKey);
		formData.append('twitter_consumer_api_secret_key', twitterConsumerApiSecretKey);
		formData.append('twitter_access_token', twitterAccessToken);
		formData.append('twitter_dev_env_label', twitterDevEnvLabel);
		formData.append('twitter_account_activity_api_tier', twitterAccountActivityApiTier);
		formData.append('twitter_access_token_secret', twitterAccessTokenSecret);

		return twitterBotAPI.postRequest(formData);
	},
	createSkypeBot(skp_app_id, skp_app_secret) {
		const formData = new FormData();
		formData.append('skp_app_id', skp_app_id);
		formData.append('skp_app_secret', skp_app_secret);

		return skypeBotAPI.postRequest(formData);
	},
	createSkypeBusinessBot(skp_app_id, skp_app_secret) {
		const formData = new FormData();
		formData.append('sfb_app_id', skp_app_id);
		formData.append('sfb_app_secret', skp_app_secret);

		return skypeBotBusinessAPI.postRequest(formData);
	},
	createSlackBot(slk_client_id, slk_client_secret, slk_verification_token) {
		const formData = new FormData();
		formData.append('slk_client_id', slk_client_id);
		formData.append('slk_client_secret', slk_client_secret);
		formData.append('slk_verification_token', slk_verification_token);

		return slackBotAPI.postRequest(formData);
	},
	createWeChatBot(wechat_app_id, wechat_app_secret) {
		const formData = new FormData();
		formData.append('wechat_app_id', wechat_app_id);
		formData.append('wechat_app_secret', wechat_app_secret);

		return weChatBotAPI.postRequest(formData);
	},
	createTeamsBot(msteams_app_id, msteams_app_secret) {
		const formData = new FormData();
		formData.append('msteams_app_id', msteams_app_id);
		formData.append('msteams_app_secret', msteams_app_secret);

		return msTeamsAPI.postRequest(formData);
	},
	getWhatsappProviders() {
		return getWhatsappProvidersAPI.getRequest();
	},
	createWhatsappBot(data) {
		const formData = new FormData();
		Object.keys(data).forEach((element) => {
			formData.append(element, data[element]);
		});

		return saveWhatsappBotAPI.postRequest(formData);
	},
	deployWhatsappChannel(data) {
		const params = {
			targetApi: 'deployWhatsappChannel',
		};
		return apiGetWayGeneralProxyApi.postRequest(data, serialize(params, '?'));
	},
	getEmailDetails() {
		const params = {
			targetApi: 'getEmailConfig',
		};

		return genericProxyRequestAPI.getRequestWithParams(params);
	},

	getSandboxDataForBotkey: (botKey, isTestAccount, isWAAcceleratorTrialAccount) => {
		const params = {
			botKey,
			isTestAccount: !!isTestAccount,
			targetApi: 'getSandboxDataForBotkey',
			isPlgAccount: isWAAcceleratorTrialAccount,
		};

		return genericProxyRequestAPI.getRequestWithParams(params, '?');
	},

	addUsersToSandboxNumber(params, body) {
		return genericProxyRequestAPI.postRequest(body, serialize(params, '?'));
	},

	addCustomerUserSandbox(params, body) {
		return genericProxyRequestAPI.postRequest(body, serialize(params, '?'));
	},

	deleteSandbox(botKey) {
		const params = {
			targetApi: 'deleteSandbox',
			botKey,
		};

		return genericProxyRequestAPI.deleteRequest(serialize(params, '?'));
	},

	submitSupportEmailAddress(data) {
		const params = {
			targetApi: 'addEmailConfig',
		};

		return genericProxyRequestAPI.postRequest(data, serialize(params, '?'));
	},
	deleteEmailChannel() {
		const params = {
			targetApi: 'deleteEmailConfig',
		};

		return genericProxyRequestAPI.deleteRequest(serialize(params, '?'));
	},
	getPartnerKey(cid) {
		const params = {
			targetApi: 'getPartnerKey',
			customerId: cid,
		};

		return genericProxyRequestAPI.getRequestWithParams(params);
	},
	saveEmailDefaultSetting(data) {
		const params = {
			targetApi: 'configureDefaultSetting',
		};

		return genericProxyRequestAPI.postRequest(data, serialize(params, '?'));
	},
	testEmailConfig(data) {
		const params = {
			targetApi: 'testEmailConfig',
		};

		return genericProxyRequestAPI.postRequest(data, serialize(params, '?'));
	},
	configureGoogleBusinessMessages: (agentId) => {
		const formData = new FormData();
		formData.append('gbm_agent_id', agentId);

		return gbmChatBotAPI.postRequest(formData);
	},
	saveGbmBot(agentId) {
		const data = {
			...agentId,
		};
		const params = {
			targetApi: 'saveGbmBotDetails',
		};

		return genericProxyRequestAPI.postRequest(data, serialize(params, '?'));
	},

	getWhatsappConfiguredBots: (nexmoContact) =>
		whatsappConfiguredBotsAPI.getRequestWithParams({ nexmo_contact: nexmoContact }),

	getWhatsappBusinessProfile() {
		const params = {
			targetApi: 'whatsappProfile',
		};

		return genericProxyRequestAPI.getRequestWithParams(params);
	},

	updateWhatsappProfileDetails(data) {
		const formData = new FormData();
		Object.keys(data).forEach((element) => {
			formData.append(element, data[element]);
		});

		return whatsappProfileDetailsAPI.postRequest(formData);
	},

	deleteWhatsappProfilePhoto() {
		const params = {
			targetApi: 'whatsappPhoto',
		};

		return genericProxyRequestAPI.deleteRequest(serialize(params, '?'));
	},
	updateMobileConfigured(data) {
		const body = {
			botRef: data.botRef,
			is_mobile_configured: data.is_mobile_configured,
		};
		const params = {
			targetApi: 'updateMobileConfigured',
		};

		return genericProxyRequestAPI.postRequest(body, serialize(params, '?'));
	},

	getWhatsappConfigration: () => {
		const params = {
			targetApi: 'getWhatsappConfigration',
			customerId: '{{customerId}}',
		};

		return genericProxyRequestAPI.getRequestWithParams(params);
	},
	getCommonWhatsappConfigration: () => {
		const params = {
			targetApi: 'getCommonWhatsappConfigration',
		};
		return apiGetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	saveCommonWhatsappConfigration: (data) => {
		const params = {
			targetApi: 'saveCommonWhatsappConfigration',
		};

		return apiGetWayGeneralProxyApi.postRequest(data, serialize(params, '?'));
	},
	removeCommonWhatsappConfigration: () => {
		const params = {
			targetApi: 'removeCommonWhatsappConfigration',
		};
		return apiGetWayGeneralProxyApi.postRequest(serialize(params, '?'));
	},
	disableChannel: (data) => {
		const formData = new FormData();
		Object.keys(data).forEach((element) => {
			formData.append(element, data[element]);
		});

		return disableChannelAPI.postRequest(formData);
	},
	getBotChannelConfigString: (platform) => {
		const params = {
			targetApi: 'getBotChannelConfigString',
			platform: platform,
		};
		return apiGetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
};
