import { useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useTranslation } from 'react-i18next';

import TemplateButtonsPopOver from 'components/shared/MessageTemplatesRevamp/TemplateButtonsPopOver';
import TemplatePreviewCards from 'components/shared/MessageTemplatesRevamp/TemplatePreviewCards/TemplatePreviewCards';

import { IObjProps } from 'shared/consts/types';
import { componentTypes, MESSAGE_TEMPLATE_COMPONENT_TYPE } from 'shared/enum/enum';
import { templateMessageformFieldIds } from 'shared/helpers';
import { BROADCAST_FORM_FIELDS_IDS, getHtmlFormatText } from 'shared/helpers/broadcastHelpers';
import WhatsAppPreviewFooter from 'shared/icons/Broadcast/WhatsAppPreviewFooter';
import WhatsAppPreviewHeader from 'shared/icons/Broadcast/WhatsAppPreviewHeader';
import { replaceVariablesInMsg } from 'shared/pathBuilderHelpers';
import {
	CAROUSEL_HEADER_TYPES,
	componentFormat,
	componentType,
	getComponentByType,
	isAuthenticationTemplate,
	templateComponentParameters,
} from 'shared/whatsappTemplateHelpers';

interface IENGTWhatsappTemplate {
	currentTemplate: IObjProps;
	templateComponent: IObjProps;
	pathVariables: IObjProps;
	getValues: Function;
}

function WhatsappTemplatePreview({
	currentTemplate,
	templateComponent,
	pathVariables,
	getValues,
}: IENGTWhatsappTemplate) {
	const [css, theme]: [any, any] = useStyletron();
	const [seeAllOptionsButtonClicked, setSeeAllOptionsButtonClicked] = useState<boolean>(false);
	const { t } = useTranslation(['common', 'pages']);

	const { OFFER_CODE, EXPIRATION_DATE_TIME } = BROADCAST_FORM_FIELDS_IDS;

	const carouselPreview = (templateComponent: any, currentTemplate: any, getValues: any) => {
		const { language } = currentTemplate || {};
		const cardComponents: Array<any> = [];
		const bodyComponent = getComponentByType(currentTemplate?.components, componentType.body);
		let body: string = bodyComponent?.text?.toString() || '';
		const cards: any = currentTemplate?.components?.[1]?.cards || '';
		const carousels: any = getValues('parameters[carousel]');
		if (templateComponent.indexOf(templateComponentParameters.body) !== -1 && getValues(`parameters.body`)) {
			getValues(`parameters.body`).forEach((param: any, index: number) => {
				if (param !== '') {
					body = body?.replaceAll(`{{${index + 1}}}`, param);
				}
			});
		}
		if (getValues(templateComponentParameters.carousel) && cards) {
			cards?.forEach((card: any, index: number) => {
				let header: any;
				const cardBodyComponent = getComponentByType(cards[index]?.components, componentType.body);
				let cardBody: any = cardBodyComponent?.text?.toString() || '';
				const buttonsArray: Array<any> = [];
				if (templateComponent.includes(`card${index}.IMAGE`)) {
					header = {
						headerFormat: CAROUSEL_HEADER_TYPES.IMAGE,
						headerInputValue: getValues(`carousel[${index}].fileUrl`),
					};
				} else if (templateComponent.includes(`card${index}.VIDEO`)) {
					header = {
						headerFormat: CAROUSEL_HEADER_TYPES.VIDEO,
						headerInputValue: getValues(`carousel[${index}].video`),
					};
				}
				const cardButtonsComponent = getComponentByType(cards?.[index]?.components, componentType.buttons);
				if (templateComponent.includes(`card${index}.buttons`)) {
					cardButtonsComponent?.buttons?.forEach((button: any, buttonIndex: any) => {
						buttonsArray.push({ label: getValues(`carousel[${index}].buttons[${buttonIndex}].text`) });
					});
				}
				if (templateComponent.includes(`card${index}.body`) && carousels?.[index]?.body) {
					carousels[index].body.forEach((param: any, index: number) => {
						if (param !== '') {
							cardBody = cardBody?.replaceAll(`{{${index + 1}}}`, param);
						}
					});
				}
				cardComponents.push({
					header,
					body: {
						bodyText: getHtmlFormatText(cardBody),
					},
					buttons: buttonsArray,
				});
			});
		}

		return {
			cardComponents,
			body,
			language,
		};
	};

	const textMediaPreview = (templateComponent: any, currentTemplate: any, getValues: any) => {
		const { language } = currentTemplate || {};
		let header: any;

		const headerComponent = getComponentByType(currentTemplate?.components, componentType.header);
		if (templateComponent.includes(componentFormat.text)) {
			let headerText: string = headerComponent?.text?.toString();
			if (getValues(`parameters.header`)) {
				getValues(`parameters.header`).forEach((param: any, index: number) => {
					headerText = headerText?.replaceAll(`{{${index + 1}}}`, param);
				});
			}
			header = {
				type: componentFormat.text,
				inputValue: headerText || '',
			};
		} else if (templateComponent.includes(componentFormat.image)) {
			header = {
				type: componentFormat.image,
				inputValue: getValues(templateComponentParameters.imageLink),
			};
		} else if (templateComponent.includes(componentFormat.video)) {
			header = {
				type: componentFormat.video,
				inputValue: getValues(templateComponentParameters.video),
			};
		} else if (templateComponent.includes(componentFormat.documentType)) {
			header = {
				type: componentFormat.documentType,
				inputValue: getValues(templateComponentParameters.docLink),
			};
		}

		const bodyComponent = getComponentByType(currentTemplate?.components, componentType.body);
		let body: string = bodyComponent?.text?.toString();
		if (templateComponent.includes(templateComponentParameters.body) && getValues(`parameters.body`)) {
			getValues(`parameters.body`).forEach((param: any, index: number) => {
				body = body?.replaceAll(`{{${index + 1}}}`, param);
			});
		}

		const ltoComponent = getComponentByType(currentTemplate?.components, componentType.lto);
		const offerTitle = ltoComponent?.limited_time_offer?.text;
		const expirationToggle = ltoComponent?.limited_time_offer?.has_expiration;

		const buttonsArray: Array<any> = [];
		if (templateComponent.includes(templateComponentParameters.buttons)) {
			getValues('buttons')?.forEach((button: any) => {
				buttonsArray.push({ label: button.text, type: button.type });
			});
		}

		const footerComponent = getComponentByType(currentTemplate?.components, componentType.footer);
		const footer: string = footerComponent?.text?.toString();

		return {
			header,
			footer,
			buttons: buttonsArray.length > 0 ? buttonsArray : [],
			bodyText: getHtmlFormatText(body),
			language,
			offerTitle,
			expirationToggle,
		};
	};

	const authenticationPreview = (templateComponent: IObjProps, currentTemplate: IObjProps, getValues: Function) => {
		const hasSecurityRecommendation = currentTemplate?.components?.some?.(
			(component: IObjProps) =>
				component.type === MESSAGE_TEMPLATE_COMPONENT_TYPE.BODY &&
				component.add_security_recommendation === true
		);

		const footerComponent = currentTemplate?.components?.find(
			(component: IObjProps) =>
				component.type === MESSAGE_TEMPLATE_COMPONENT_TYPE.FOOTER &&
				componentTypes.code_expiration_minutes in component
		);

		const expirationTime = footerComponent?.code_expiration_minutes || null;

		const buttonsArray: Array<any> = [];
		if (templateComponent.includes(templateComponentParameters.buttons)) {
			getValues('buttons')?.forEach((button: any, index: any) => {
				buttonsArray.push({ label: button?.text });
			});
		}

		return {
			isSecurityDisclaimerPresent: hasSecurityRecommendation,
			isCodeExpirationPresent: !!footerComponent,
			buttons: buttonsArray,
			expirationTime,
		};
	};

	const renderContent = () => {
		if (templateComponent.includes(templateComponentParameters.carousel)) {
			const { cardComponents, body, language } = carouselPreview(templateComponent, currentTemplate, getValues);

			return (
				<div className={css({ flex: '1', overflowY: 'scroll' })}>
					<TemplatePreviewCards
						carouselPreviewCardsData={cardComponents}
						bubbleText={replaceVariablesInMsg(body, pathVariables)}
						language={language}
					/>
				</div>
			);
		}
		if (templateComponent.includes(templateComponentParameters.lto)) {
			const { header, footer, buttons, bodyText, language, offerTitle, expirationToggle } = textMediaPreview(
				templateComponent,
				currentTemplate,
				getValues
			);

			return (
				<div className={css({ flex: 1, overflowY: 'auto' })}>
					<TemplatePreviewCards
						isLTOComponent
						offerTitle={offerTitle || ''}
						expirationToggle={expirationToggle || false}
						offerCode={getValues(OFFER_CODE) || ''}
						expirationTime={getValues(EXPIRATION_DATE_TIME) / 1000 || ''}
						header={header}
						footer={footer}
						buttons={buttons}
						bodyText={bodyText}
						language={language}
					/>
				</div>
			);
		}
		if (isAuthenticationTemplate(currentTemplate)) {
			const { isSecurityDisclaimerPresent, isCodeExpirationPresent, expirationTime, buttons } =
				authenticationPreview(templateComponent, currentTemplate, getValues);

			const verificationCodeText = t(
				'pages:broadcast.messageTemplates.createTemplate.authentication.verificationCode'
			)
				.replace(/{openBrace}/g, '{')
				.replace(/{closeBrace}/g, '}');

			return (
				<>
					<TemplatePreviewCards
						isAuthenticationTemplate
						securityDisclaimer={isSecurityDisclaimerPresent}
						codeExpiration={isCodeExpirationPresent}
						buttons={buttons}
						bodyText={verificationCodeText}
						otpExpirationTime={expirationTime}
						variables={getValues(templateMessageformFieldIds.PARAMETERS)}
					/>
				</>
			);
		}

		const { header, footer, buttons, bodyText, language } = textMediaPreview(
			templateComponent,
			currentTemplate,
			getValues
		);

		return (
			<TemplatePreviewCards
				header={header}
				footer={footer}
				buttons={buttons}
				bodyText={bodyText}
				language={language}
				setSeeAllOptionsButtonClicked={setSeeAllOptionsButtonClicked}
				setButtonsArray={buttons}
			/>
		);
	};

	const { buttons } = textMediaPreview(templateComponent, currentTemplate, getValues);

	return (
		<Block
			height='30rem'
			overflow='hidden'
			display='flex'
			flexDirection='column'
			marginBottom='3rem'
			justifyContent='space-between'
			backgroundColor={theme.colors.whatsAppBackground}
			width='max-content'
			className={css({ borderRadius: '0.5rem' })}
		>
			<WhatsAppPreviewHeader />
			{renderContent()}
			{seeAllOptionsButtonClicked ? (
				<TemplateButtonsPopOver
					setSeeAllOptionsButtonClicked={setSeeAllOptionsButtonClicked}
					heading={t('common:allOptions')}
					buttonsArray={buttons}
				/>
			) : (
				<WhatsAppPreviewFooter />
			)}
		</Block>
	);
}
export default WhatsappTemplatePreview;
