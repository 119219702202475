import { Suspense, useEffect, useState } from 'react';
import { BaseProvider } from 'baseui';
import { Block } from 'baseui/block';
import mixpanel from 'mixpanel-browser';
import ReactPixel from 'react-facebook-pixel';
import ga4 from 'react-ga4';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';

import BotChangeDetector from 'components/BotChangeDetector/BotChangeDetector';
import ChatComponent from 'components/ChatPopup/ChatPopup';
import ENGTErrorBoundary from 'components/ENGTErrorBoundary/ENGTErrorBoundary';
import JitsiApp from 'components/Jitsi/JitsiApp';
import MarkOnlineWrapper from 'components/MarkOnlinePromptModal/MarkOnlineAgent';
import PageFooter from 'components/PageFooter/PageFooter';
import PricingExitIntentHOC from 'components/PricingPlans/PricingExitIntentHOC';
import ResetAccountPassword from 'components/ResetAccountPassword/ResetAccountPassword';
import PostHog from 'components/shared/DataTracking/PostHog';
import TimeTrackingForGA4 from 'components/shared/DataTracking/TimeTrackingForGA4';
import SoundNotification from 'components/SoundNotification/SoundNotification';
import { ENGTBanner } from 'components/UI/ENGTBanner/ENGTBanner';
import ENGTMask from 'components/UI/ENGTMask/ENGTMask';
import ENGTToaster from 'components/UI/ENGTToaster/ENGTToaster';
import Loader from 'components/UI/Loader/Loader';
import ErrorSavingModal from 'components/UI/Modal/ErrorSavingModal/ErrorSavingModal';
import InfoModal from 'components/UI/Modal/InfoModal/InfoModal';
import WANudgesOnLogin from 'components/WAAccNudges/WANudgesOnLogin';

import { REACT_APP_BASE_URL } from 'shared/config/config';
import { ACCOUNT_TYPES } from 'shared/consts/consts';
import { FB_PIXEL_ID, GTM_ID, MIXPANEL_TOKEN } from 'shared/consts/OnBoarding';
import { IObjProps } from 'shared/consts/types';
import { GTMTrackingIds } from 'shared/enum/trackingEnums';
import {
	clearLsOnLogout,
	getResponsiveVH,
	getUrlParams,
	isActualCustomer,
	isDevEnvironment,
	isTestEmail,
	isWindows,
	setDefaultFavIcon,
	updatePageTitle,
} from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import { sendPageViewDataToGA4, sendTimeTrackingDataToGA4 } from 'shared/trackingHelpers';

import { getNotificationFromLS } from 'store/App/Notifications';
import { onboardingAPIs } from 'store/App/Onboarding/actions';
import { themePackSelector, themeValueSelector } from 'store/App/Preferences/selectors';
import { fetchRefreshTokenAction, getPublicKey } from 'store/App/Token';
import { getGeoLocationData } from 'store/App/User';
import {
	fontStyleSelector,
	isShopifyEnabledAcc,
	isTestAccountSelector,
	isWhatsappAcceleratorEnabled,
} from 'store/App/User/selectors';
import { common } from 'store/Common';
import { RootState } from 'store/rootReducer';
import { initSocket, SocketContext, socketReconnectionListener } from 'store/Socket';
import { botAdminValue, isEngatiBrandSelector } from 'store/Users/selectors';

import ENGTDarkTheme from 'theme/ENGTDarkTheme';
import ENGTLightTheme from 'theme/ENGTLightTheme';

import isAuthenticatedRoute from 'router/helpers/isAuthenticatedRoute';
import { NoAuthenticationRouteLinks } from 'router/links/NoAuthenticationRouteLinks';
import { NoColumnRouteLinks } from 'router/NoColumnRoutes';
import Router from 'router/Router';
import RouterWrapper from 'router/RouterWrapper';

import './App.scss';

const tagManagerArgs = {
	gtmId: GTM_ID,
};

const TRACKING_ID = 'G-KZZQ484ZNE';
const TRACKING_ID_QA = 'G-CYT4L3RHX4';
const HOTJAR_SITE_ID = parseInt(process.env.HOTJAR_SITE_ID || '');
const HOTJAR_SITE_ID_QA = parseInt(process.env.HOTJAR_SITE_ID_QA || '');
const HOTJAR_VERSION = 6;

const THEME = {
	light: 'light',
	dark: 'dark',
	system: 'system',
};

const { whatsappSetup } = NoColumnRouteLinks;

const onlyLightThemeRoutes = [whatsappSetup];

const createSocketConnection = () => {
	let socketConnectionUrl = REACT_APP_BASE_URL;

	if (REACT_APP_BASE_URL.indexOf('http://localhost') > -1) {
		socketConnectionUrl = 'https://dev.engati.com';
	}

	return io(socketConnectionUrl, {
		path: '/socket.io',
		transports: ['websocket'],
		autoConnect: true,
	});
};

const isLightThemeRoute = (() => {
	const cache: IObjProps = {};

	return (pathname: string) => {
		if (cache[pathname] !== undefined) {
			return cache[pathname];
		}

		let isLightTheme = false;

		for (let i = 0; i < onlyLightThemeRoutes.length; i++) {
			if (matchPath(onlyLightThemeRoutes[i], pathname)) {
				isLightTheme = true;
				break;
			}
		}

		cache[pathname] = isLightTheme;

		return isLightTheme;
	};
})();

const App = function () {
	const [socket, setSocket] = useState<any>();
	const [theme, setTheme] = useState(THEME.light);

	const allBotsList = useSelector((state: RootState) => state.BotList.data);
	const userDetail: string = useSelector((state: RootState) => state.User.botAdmin.data.uid);
	const brandName: string = useSelector((state: RootState) => state.User.botAdmin.data.branding_platform_name);
	const favIconUrl: string = useSelector((state: RootState) => state.User.botAdmin.data.branding_favicon_path);
	const themeColor: string = useSelector((state: RootState) => state.User.botAdmin.data.theme_color);
	const toastDuration = useSelector((state: RootState) => state.Preferences.toaster.toastDuration);
	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);
	const isMasterAdmin = localStorage.getItem('loggedInUserAccountType') === ACCOUNT_TYPES.MASTER_ADMIN;
	const themePack: string = useSelector(themePackSelector);
	const themeValue = useSelector(themeValueSelector);
	const fontStyle: string = useSelector(fontStyleSelector);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const isTestAccount = useSelector(isTestAccountSelector);
	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const botAdminData: IObjProps = useSelector(botAdminValue);
	const { email } = botAdminData;
	const isWhatsappAcceleratorCustomer = useSelector(isWhatsappAcceleratorEnabled);

	const dispatch: any = useDispatch();
	const { pathname } = useLocation();
	const location = useLocation();
	const navigate = useNavigate();
	const params = getUrlParams();
	const utmCampaign = params.get('utm_campaign') || '';
	const { isSmallDesktopScreen } = useResponsiveSize();

	const systemThemeElement = window.matchMedia && window.matchMedia?.('(prefers-color-scheme: dark)');

	const setSystemTheme = (event: any) => {
		if (event.matches) {
			setTheme(THEME.dark);
		} else {
			setTheme(THEME.light);
		}
	};

	const setThemeSelected = () => {
		if (themeValue === 'DARK') {
			setTheme(THEME.dark);
		} else if (themeValue === 'LIGHT') {
			setTheme(THEME.light);
		} else if (systemThemeElement) {
			setSystemTheme(systemThemeElement);
		}
	};

	const onboardingApis = () => {
		dispatch(onboardingAPIs());
	};

	const showErrorPage = () => {
		navigate('/page-not-found', { replace: true });
	};

	useEffect(() => {
		mixpanel.init(MIXPANEL_TOKEN, { debug: true });
		if (!isDevEnvironment()) {
			ReactPixel.init(FB_PIXEL_ID);
		}

		if (params.get('csrfToken') === 'True') {
			localStorage.setItem('csrfToken', 'true');
		}

		if (isAuthenticatedRoute(pathname)) {
			const csrfToken = localStorage.getItem('csrfToken');
			if (csrfToken) {
				dispatch(fetchRefreshTokenAction(onboardingApis));
			} else {
				window.location.replace(`${REACT_APP_BASE_URL}/admin/login`);
			}
		} else {
			clearLsOnLogout(botRef, userDetail);
		}
	}, []);

	const isWAAcceleratorRegistrationPage = () => pathname === NoAuthenticationRouteLinks.whatsappGetStarted;

	const isValidWAAcceleratorCustomer = () =>
		(isWhatsappAcceleratorCustomer && !isTestEmail(email)) ||
		(isWAAcceleratorRegistrationPage() && isActualCustomer(utmCampaign));

	const enableAnalytics = () => {
		TagManager.initialize(tagManagerArgs);
		isValidWAAcceleratorCustomer() && dispatch(common.enablePostHogAction());
	};

	const disableAnalytics = () => {
		TagManager.initialize({
			gtmId: '',
		});
		dispatch(common.disablePostHogAction());

		if ((window as any).dataLayer) {
			(window as any).dataLayer = [];
		}
	};

	useEffect(() => {
		const isEngatiNonAuthenticRoute =
			isTestAccount === undefined && isEngatiBrand === undefined && window.location.href.includes('app.engati');

		if (!isDevEnvironment() && ((!isTestAccount && isEngatiBrand) || isEngatiNonAuthenticRoute)) {
			enableAnalytics();
		} else {
			disableAnalytics();
		}
	}, [isTestAccount, isEngatiBrand]);

	useEffect(() => {
		let themeChangeListener: any;

		if (!isAuthenticatedRoute(pathname) || isLightThemeRoute(pathname)) {
			if (theme !== THEME.light) {
				setTheme(THEME.light);
			}
		} else if (theme !== themeValue.toLocaleLowerCase()) {
			setThemeSelected();
			if (systemThemeElement) {
				try {
					themeChangeListener = systemThemeElement.addEventListener('change', setSystemTheme);
				} catch (e: any) {
					try {
						themeChangeListener = systemThemeElement.addListener(setSystemTheme);
					} catch (e) {
						console.error(e);
					}
				}
			}
		}

		return () => {
			if (themeChangeListener) {
				try {
					systemThemeElement.removeEventListener('change', themeChangeListener);
				} catch (e: any) {
					systemThemeElement.removeListener(setSystemTheme);
				}
			}
		};
	}, [themeValue, pathname, setTheme]);

	useEffect(() => {
		const handleExternalLinkClick = (e: MouseEvent) => {
			const myLink: HTMLLinkElement | null = (e.target as HTMLElement).closest('#external-link');
			if (myLink) {
				e.preventDefault();
				const url = myLink.href.split('?_gl=')[0];

				const bypassGA = async () => {
					await new Promise((resolve) => setTimeout(resolve, 0));
					window.open(url);
				};

				bypassGA();
			}
		};

		document.addEventListener('click', handleExternalLinkClick);

		return () => document.removeEventListener('click', handleExternalLinkClick);
	}, []);

	useEffect(() => {
		setSocket(createSocketConnection());
	}, []);

	useEffect(() => {
		if (socket && userDetail) {
			dispatch(socketReconnectionListener(socket));
			dispatch(initSocket(socket));
		}
		dispatch(getNotificationFromLS());
	}, [dispatch, socket, allBotsList, userDetail]);

	useEffect(() => {
		setDefaultFavIcon(favIconUrl);
		updatePageTitle(brandName ? `${brandName} - Portal` : '');
	}, [favIconUrl, brandName]);

	useEffect(() => {
		if (!isDevEnvironment()) {
			ga4.initialize(TRACKING_ID);
			if (isShopifyEnabled && !isMasterAdmin && !isNaN(HOTJAR_SITE_ID)) {
				hotjar.initialize(HOTJAR_SITE_ID, HOTJAR_VERSION);
			}
		} else {
			ga4.initialize(TRACKING_ID_QA);
			if (isShopifyEnabled && !isMasterAdmin && !isNaN(HOTJAR_SITE_ID)) {
				hotjar.initialize(HOTJAR_SITE_ID_QA, HOTJAR_VERSION);
			}
		}
	}, []);

	useEffect(() => {
		if (!isDevEnvironment()) {
			const path = location.pathname + location.search;
			ga4.send({
				hitType: 'pageview',
				page: path,
			});
			sendPageViewDataToGA4(path);
		}
	}, [location]);

	useEffect(() => {
		if (window.location.href.includes('app.engati')) {
			const _paq = (window as any)._paq || [];
			_paq.push(['setCustomUrl', window.location.href]);
			_paq.push(['setDocumentTitle', document.title]);
			_paq.push(['trackPageView']);
		}
	}, [location]);

	useEffect(() => {
		dispatch(getPublicKey(showErrorPage));
		dispatch(getGeoLocationData());
	}, []);

	return (
		<SocketContext.Provider value={socket}>
			<BaseProvider
				theme={
					theme === THEME.light
						? ENGTLightTheme({ themeColor, fontStyle, themePack })
						: ENGTDarkTheme({ themeColor, fontStyle, themePack })
				}
			>
				{location.pathname !== '/login' &&
					location.pathname !== '/bots' &&
					location.pathname !== '/page-not-found' &&
					!location.pathname.includes('/get-started') &&
					!location.pathname.includes('/messages') &&
					!isSmallDesktopScreen && <ChatComponent />}
				<Suspense fallback={<Loader />}>
					<MarkOnlineWrapper />
				</Suspense>
				<ENGTErrorBoundary message='' height={getResponsiveVH(100)} showIcon>
					<ENGTMask>
						<Block backgroundColor='backgroundPrimary' className={`${isWindows() ? 'windows-screen' : ''}`}>
							<Suspense fallback={<Loader />}>
								<ENGTBanner>
									<div className='App' style={{ position: 'relative' }}>
										<RouterWrapper>
											<Router />
										</RouterWrapper>
									</div>
								</ENGTBanner>
							</Suspense>
							<Suspense fallback={<Loader />}>
								<ErrorSavingModal />
								<JitsiApp />
							</Suspense>
							<Suspense fallback={<Loader />}>
								<InfoModal />
								<PricingExitIntentHOC />
								<ResetAccountPassword />
								<BotChangeDetector />
							</Suspense>
							<ENGTToaster toastDuration={toastDuration} />
							<SoundNotification />
							<TimeTrackingForGA4
								gtmTrackingId={GTMTrackingIds.TIME_SPENT_ON_PORTAL}
								trackerFunction={sendTimeTrackingDataToGA4}
							/>
							<PostHog />
						</Block>
						<Suspense>
							<WANudgesOnLogin />
						</Suspense>
					</ENGTMask>
				</ENGTErrorBoundary>
				{location.pathname !== '/login' &&
					location.pathname !== '/bots' &&
					location.pathname !== '/page-not-found' &&
					!location.pathname.includes('/get-started') && <PageFooter />}
			</BaseProvider>
		</SocketContext.Provider>
	);
};

export default App;
